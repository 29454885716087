import { Component } from '@angular/core';

@Component({
  selector: 'app-patronato',
  templateUrl: './patronato.component.html',
  styleUrls: ['./patronato.component.scss']
})
export class PatronatoComponent {

}
