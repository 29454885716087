import { Component } from '@angular/core';

@Component({
  selector: 'app-tipo-tramite',
  templateUrl: './tipo-tramite.component.html',
  styleUrls: ['./tipo-tramite.component.scss']
})
export class TipoTramiteComponent {

}
