import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Component } from '@angular/core';
@Component({
  selector: 'app-recursos-pi',
  templateUrl: './recursos-pi.component.html',
  styleUrls: ['./recursos-pi.component.scss']
})
export class RecursosPiComponent {
  razon:any
  concepto:any
  cantidad:any
  solicitante:any
   archivos: string[] = [];
 estatus:any

   //Variables para url de archivos
   urlDocumento = '';
   urlDocumento1 = '';
   urlDocumento2 = '';
   urlDocumento3 = '';
   urlDocumento4 = '';
   urlDocumento5 = '';
   urlDocumento6 = '';
   urlDocumento7 = '';

   //menu de registro

calendario:any
capacitacion:any
comunicaciones:any
directorio:any
organizacion:any
preventivo:any
proposito:any
recursosh:any
riesgo:any
riesgos:any
datos:any
auxilio:any

   //Variables para indicar carga de archivos
   subiendoDocumento = false;
   subiendoDocumento1 = false;
   subiendoDocumento2 = false;
   subiendoDocumento3 = false;
   subiendoDocumento4 = false;
   subiendoDocumento5 = false;
   subiendoDocumento6 = false;
   subiendoDocumento7 = false;



   nuevoPC = new FormGroup({
     FUNDAMENTO: new FormControl('', Validators.required),
     PLANTILLA: new FormControl('', Validators.required),
     FUN_DEPE: new FormControl('', Validators.required),
     INMUEBLE: new FormControl('', Validators.required),
     FUN_REQUE: new FormControl('', Validators.required),
     REQUERIMENTOS: new FormControl('', Validators.required),
     EMPRESA: new FormControl('', Validators.required)


   });

   constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,private storage: Storage,) { }

   ngOnInit(): void {
    if(localStorage.getItem('comunicaciones')){
      this.comunicaciones=true
    }

    if(localStorage.getItem('calendario')){
      this.calendario=true
    }
    if(localStorage.getItem('capacitacion')){
      this.capacitacion=true
    }
    if(localStorage.getItem('directorio')){
      this.directorio=true
    }
    if(localStorage.getItem('organizacion')){
      this.organizacion=true
    }
    if(localStorage.getItem('preventivo')){
      this.preventivo=true
    }
    if(localStorage.getItem('proposito')){
      this.proposito=true
    }
    if(localStorage.getItem('recursosh')){
      this.recursosh=true
    }

    if(localStorage.getItem('riesgo')){
      this.riesgo=true
    }
    if(localStorage.getItem('riesgos')){
      this.riesgos=true
    }  if(localStorage.getItem('auxilio')){
      this.auxilio=true
    }
   }


   postForm(form: any) {




     this.alerts.alertaConfirmacionAgregar('Registro', '¿Desea enviar los datos?')
       .then((res: any) => {

         if (res.isConfirmed) {
           if (form.FUNDAMENTO !== '' &&
           form.PLANTILLA !== '' &&
           form.FUN_DEPE !== '' &&
           form.INMUEBLE !== '' &&
           form.FUN_REQUE !== '' &&
           form.REQUERIMENTOS !== ''







           ) {
             console.log(form);
this.nuevoPC.value.EMPRESA=localStorage.getItem('empresa')
             this.api.PIRecursos(form).subscribe(data => {
               console.log(data);

              localStorage.setItem('empresa', form.EMPRESA)
               this.alerts.realizado('Completado', 'Se han enviado los datos con exito').then((res: any) => {
                localStorage.setItem('recursosh','1')
this.router.navigate(['admin/proteccion-civil/pc/interno/comunicaciones'])
               })

             }, error => {
console.log(error);

               this.alerts.alertaError('Ups', 'Error de registro')
             })

           } else {
             this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios');
           }

         }

       })

   }



  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })



   async changeFileMenu(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);

          this.nuevoPC.value.FUNDAMENTO = file;
          console.log(this.nuevoPC.value.FUNDAMENTO);

        }).catch(error => { console.log(error) });

      })
    })

  }

  async changeFileMenu1(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento1 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento1 = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento1 = false;
          console.log(file);

          this.nuevoPC.value.PLANTILLA = file;
          console.log(this.nuevoPC.value.PLANTILLA);

        }).catch(error => { console.log(error) });

      })
    })

  }

  async changeFileMenu2(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento2 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento2 = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento2 = false;
          console.log(file);

          this.nuevoPC.value.FUN_DEPE = file;
          console.log(this.nuevoPC.value.FUN_DEPE);

        }).catch(error => { console.log(error) });

      })
    })

  }


  async changeFileMenu3(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento3 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento3 = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento3 = false;
          console.log(file);

          this.nuevoPC.value.INMUEBLE = file;
          console.log(this.nuevoPC.value.INMUEBLE);

        }).catch(error => { console.log(error) });

      })
    })

  }


  async changeFileMenu4(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento4 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento4 = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento4 = false;
          console.log(file);

          this.nuevoPC.value.FUN_REQUE = file;
          console.log(this.nuevoPC.value.FUN_REQUE);

        }).catch(error => { console.log(error) });

      })
    })

  }



  async changeFileMenu5(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento5 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento5 = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento5 = false;
          console.log(file);

          this.nuevoPC.value.REQUERIMENTOS = file;
          console.log(this.nuevoPC.value.REQUERIMENTOS);

        }).catch(error => { console.log(error) });

      })
    })

  }


 }
