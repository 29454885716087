import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.scss']
})
export class HospitalComponent {
  boton: any;
  id: any;
  data: any;
  areas: any;
  area: any;
  subarea: any;
  areas1: any;
  menu: any;
  titulo: any;
  icono: any;
  archivos: string[] = [];
  subscribeTimer: any;
  animation = true;
  cuerpo:any




  nuevoPC2 = new FormGroup({
    LUGAR_VI: new FormControl('', Validators.required),
    FOLIO: new FormControl('', Validators.required),
    ARMA_BLANCA: new FormControl('', Validators.required),
    CAIDA: new FormControl('', Validators.required),
    VOLCADURA: new FormControl('', Validators.required),
    GOLPE: new FormControl('', Validators.required),
    COLICION: new FormControl('', Validators.required),
    ATROPELLAMIENTO: new FormControl('', Validators.required),
    ANIMAL: new FormControl('', Validators.required),
    PUNZANTE: new FormControl('', Validators.required),
    HUMANO: new FormControl('', Validators.required),

    FUEGO: new FormControl('', Validators.required),
    EXPLOSION: new FormControl('', Validators.required),
    EXPLORACION: new FormControl('', Validators.required),
    TOXICA: new FormControl('', Validators.required),
    ELECTRICIDAD: new FormControl('', Validators.required),
    PROYECTIL: new FormControl('', Validators.required),
    MAQUINARIA: new FormControl('', Validators.required),
    LIQUIDOS: new FormControl('', Validators.required),
    LESION: new FormControl('', Validators.required),
    PUPILAS: new FormControl('', Validators.required),

    GESTA: new FormControl('', Validators.required),
    CESAREAS: new FormControl('', Validators.required),
    PARTOS: new FormControl('', Validators.required),
    ABORTOS: new FormControl('', Validators.required),
    FECHA_PARTO: new FormControl('', Validators.required),
    SEMANAS: new FormControl('', Validators.required),
    MEMBRANA_ROTA: new FormControl('', Validators.required),
    INTEGRA: new FormControl('', Validators.required),
    SANGRADO: new FormControl('', Validators.required),
    CONTRACCIONES: new FormControl('', Validators.required),

    FRECUENCIA: new FormControl('', Validators.required),
    DURACION: new FormControl('', Validators.required),
    HORA_NACIMIENTO: new FormControl('', Validators.required),
    PRODUCTO: new FormControl('', Validators.required),
    SEXO: new FormControl('', Validators.required),
    LUGAR_VII: new FormControl('', Validators.required),
    PLACENTA_EXPULSADA: new FormControl('', Validators.required),
    A_P_G_A_R: new FormControl('', Validators.required),
    RCP_VII: new FormControl('', Validators.required),
    SILVERMAN: new FormControl('', Validators.required),


    OXIGENOTERAPIA: new FormControl('', Validators.required),
    DSP: new FormControl('', Validators.required),
    CRUZ_ROJA: new FormControl('', Validators.required),
    PF: new FormControl('', Validators.required),
    SEC_MOVILIDAD: new FormControl('', Validators.required),
    PGR: new FormControl('', Validators.required),
    MP: new FormControl('', Validators.required),
    OTROS_VII: new FormControl('', Validators.required),
    OTRO_VIII: new FormControl('', Validators.required),
    UNIDADES1: new FormControl('', Validators.required),

    UNIDADES2: new FormControl('', Validators.required),
    UNIDADES3: new FormControl('', Validators.required),
    PERSONAL: new FormControl('', Validators.required),
    PERSONAL2: new FormControl('', Validators.required),
    PERSONAL3: new FormControl('', Validators.required),
    AGENTE: new FormControl('', Validators.required),
    CED_PROFESIONAL: new FormControl('', Validators.required),
    TIPO: new FormControl('', Validators.required),
    TIPO1: new FormControl('', Validators.required),
    TIPO2: new FormControl('', Validators.required),

    TIPO3: new FormControl('', Validators.required),
    COLOR1: new FormControl('', Validators.required),
    COLOR2: new FormControl('', Validators.required),
    COLOR3: new FormControl('', Validators.required),
    MARCA1: new FormControl('', Validators.required),
    MARCA2: new FormControl('', Validators.required),
    MARCA3: new FormControl('', Validators.required),
    PLACAS1: new FormControl('', Validators.required),
    PLACAS2: new FormControl('', Validators.required),
    PLACAS3: new FormControl('', Validators.required),

    LESIONADOS1: new FormControl('', Validators.required),
    LESIONADOS2: new FormControl('', Validators.required),
    LESIONADOS3: new FormControl('', Validators.required),


  });

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

  ngOnInit(): void {



  }



  postForm2(form: any) {



this.nuevoPC2.value.FOLIO=localStorage.getItem('id')
this.nuevoPC2.value.PUPILAS='1'


console.log(this.nuevoPC2.value);

            this.api.registroHospitalario2(form).subscribe(data => {
              console.log(data);

              this.alerts.realizado('Enviado','Registro Realizado')
              this.router.navigate(['admin/proteccion-civil/servicios'])
            }, error => {

              this.alerts.alertaError('Ups', 'Error de registro')
            })



      }







parteDelCuerpo1(){
  localStorage.setItem('cuerpo', '1');
  this.mostrarImagen();

}

parteDelCuerpo2(){
  localStorage.setItem('cuerpo', '2');
  this.mostrarImagen();

}


mostrarImagen(){
  this.cuerpo === localStorage.getItem('cuerpo');
  console.log(this.cuerpo);

}

}
