import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-ficha-p',
  templateUrl: './ficha-p.component.html',
  styleUrls: ['./ficha-p.component.scss']
})
export class FichaPComponent {
 razon:any
 concepto:any
 cantidad:any
 solicitante:any
  archivos: string[] = [];
estatus:any

  nuevoPC = new FormGroup({
    RAZON: new FormControl('', Validators.required),
    SOLICITANTE: new FormControl('', Validators.required),
    CONCEPTO_PAGO: new FormControl('', Validators.required),
    CANTIDAD: new FormControl('', Validators.required),


  });

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

  ngOnInit(): void {
this.nuevoPC.setValue({
  RAZON:'',
  CONCEPTO_PAGO:'PERITAJE, DICTAMEN E INSPECCION DE CARACTER',
  CANTIDAD:'$2,700.00',
  SOLICITANTE:''
})
  }


  postForm(form: any) {
    this.crearPDF()



    this.alerts.alertaConfirmacionAgregar('Generar Ficha de Pago', '¿Desea enviar los datos?')
      .then((res: any) => {

        if (res.isConfirmed) {
          if (form.RAZON !== '' &&
            form.SOLICITANTE !== ''


          ) {
            console.log(form);

            this.api.fichapago(form).subscribe(data => {
              console.log(data);
              this.alerts.realizado('Completado', 'Se han enviado los datos con exito').then((res: any) => {

              })

            }, error => {

              this.alerts.alertaError('Ups', 'Error de registro')
            })

          } else {
            this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios');
          }

        }

      })

  }


  crearPDF() {

this.razon=this.nuevoPC.value.RAZON
this.concepto=this.nuevoPC.value.CONCEPTO_PAGO
this.cantidad=this.nuevoPC.value.CANTIDAD
this.solicitante=this.nuevoPC.value.SOLICITANTE
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "cm",
      format: [8, 13.9]

    });

    var logo = new Image();
    logo.src = 'https://i.imgur.com/oGuTsJG.png';
    doc.addImage(logo, 'JPG', .5, .5, 2, 2);

    {
      doc.addFont('Courier', 'Arial', 'normal');
      doc.setFont('Courier');
      doc.setFontSize(12);
      doc.text('Ficha de Pago', 7, 1.1, { align: "center" });
      doc.text('Programa Interno PC y Bomberos', 7, 1.5, { align: "center" });

      doc.setTextColor(68, 114, 196);
    }


    {

      doc.setTextColor(5, 93, 95);
      doc.addFont('Courier', 'Arial', 'normal');
      doc.setFont('Courier');
      doc.text('Datos de Ficha', 7, 3, { align: "center" });

    }

    {

      doc.addFont('Courier', 'Arial', 'normal');
      doc.setFont('Courier');
      doc.text('Razón Social:', .2, 4, );
      doc.text(this.razon, .2, 4.5, );

      doc.text('Concepto de Pago:', .2, 5, );
      doc.text(this.concepto, .2, 5.5, );


      doc.text('Solicitante:', .2, 6, );
      doc.text(this.solicitante, .2, 6.5, );

      doc.text('Cantidad:', .2, 7, );
      doc.text(this.cantidad + ' pesos' , .2, 7.5, );
    }

    {




      doc.output('dataurlnewwindow', { filename: 'Impresión de recibos' });
      //doc.save('No. Recibo: '+this.documento[0].NO_RECIBO + '.pdf')


    }





  }


}
