import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
declare const printDiv: any;
@Component({
  selector: 'app-hospitalario-id',
  templateUrl: './hospitalario-id.component.html',
  styleUrls: ['./hospitalario-id.component.scss']
})
export class HospitalarioIdComponent {
  razon:any
  concepto:any
  cantidad:any
  solicitante:any
   archivos: string[] = [];
 estatus:any
 activos:any
 extra:any

id:any
fecha:any
dia:any
hora_salida:any
hora_entrada:any
ambulancia:any
opera:any
bombe:any
hora_tras:any
hora_arri:any
hora_entr:any
nombre:any
municip:any
sexo:any
afilia:any
domici:any
tel:any
edad:any
otro:any
motivo:any
lugar:any
conciencia:any
ocular:any
verbal:any
motora:any
total:any
piel:any
respira:any
hora:any
fr:any
fc:any
temp:any
ta:any
avdi:any
glasgow:any
glucosa:any
pupilas:any
llen:any
so2:any
alergias:any
medica:any
enfe:any
ult_comida:any
eventos_prev:any
triage:any
evaluacion:any
trata:any
inm_cer:any
collarin:any
inm_espina:any
empaque:any

orofa:any
aspirac:any
ovace:any
oxige:any
bvm:any
nasa:any
feru:any
canu:any
toraco:any
apli_med:any
rcp:any
desfi:any
asepsia:any
oclusi:any
abulsi:any
humed:any
venda:any
cura:any
control:any
inm_obj:any
parto:any
psico:any
canaliz:any
dextro:any
cant:any
otros_manejo:any
solucion:any
mili:any
catet:any
farma:any
medico:any
mat:any
neuro:any
diges:any
metabo:any
cardio:any
endoc:any
osteo:any
respi:any
renal:any
alter:any
uroge:any
gineco:any
hemat:any
otro_causa:any
especifi:any
antec:any
lugar_vi:any
ubi:any
serv:any



nuevoPC = new FormGroup({
  FECHA: new FormControl('', Validators.required),
  DIA: new FormControl('', Validators.required),
  HORA_SALIDA: new FormControl('', Validators.required),
  HORA_ENTRADA: new FormControl('', Validators.required),
  AMBULANCIA: new FormControl('', Validators.required),
  OPERADOR: new FormControl('', Validators.required),
  BOMBEROS: new FormControl('', Validators.required),
  HORA_TRASLADO: new FormControl('', Validators.required),
  HORA_ARRIBO: new FormControl('', Validators.required),
  HORA_ENTREGA: new FormControl('', Validators.required),
  NOMBRE: new FormControl('', Validators.required),
  MUNICIPIO: new FormControl('', Validators.required),
  SEXO: new FormControl('', Validators.required),
  AFILIACION: new FormControl('', Validators.required),
  DOMICILIO: new FormControl('', Validators.required),
  TELEFONO: new FormControl('', Validators.required),
  EDAD: new FormControl('', Validators.required),
  OTRO: new FormControl('', Validators.required),
  MOTIVO: new FormControl('', Validators.required),
  LUGAR: new FormControl('', Validators.required),
  CONCIENCIA: new FormControl('', Validators.required),
  OCULAR: new FormControl('', Validators.required),
  VERBAL: new FormControl('', Validators.required),
  MOTORA: new FormControl('', Validators.required),
  TOTAL: new FormControl('', Validators.required),
  PIEL: new FormControl('', Validators.required),
  RESPIRACION: new FormControl('', Validators.required),
  HORA: new FormControl('', Validators.required),
  FR: new FormControl('', Validators.required),
  FC: new FormControl('', Validators.required),
  TEMP: new FormControl('', Validators.required),
  TA: new FormControl('', Validators.required),
  AVDI: new FormControl('', Validators.required),
  GLASGOW: new FormControl('', Validators.required),
  GLUCOSA: new FormControl('', Validators.required),
  PUPILAS: new FormControl('', Validators.required),
  LLEN_CAP: new FormControl('', Validators.required),
  SO2: new FormControl('', Validators.required),
  ALERGIAS: new FormControl('', Validators.required),
  MEDICAMENTOS: new FormControl('', Validators.required),
  ENFE_Y_CIRUGIAS: new FormControl('', Validators.required),
  ULT_COMIDA: new FormControl('', Validators.required),
  EVENTOS_PREV: new FormControl('', Validators.required),
  TRIAGE: new FormControl('', Validators.required),
  EVALUACION_PRI: new FormControl('', Validators.required),
  TRATAMIENTO: new FormControl('', Validators.required),
  INM_CERVICAL: new FormControl('', Validators.required),
  COLLARIN: new FormControl('', Validators.required),
  INM_ESPINAL: new FormControl('', Validators.required),
  EMPAQUETAMIENTO: new FormControl('', Validators.required),
  OROFARINGEA: new FormControl('', Validators.required),
  ASPIRACION: new FormControl('', Validators.required),
  OVACE: new FormControl('', Validators.required),
  OXIGENACION: new FormControl('', Validators.required),
  BVM: new FormControl('', Validators.required),
  NASALES: new FormControl('', Validators.required),
  FERULA: new FormControl('', Validators.required),
  CANULA: new FormControl('', Validators.required),
  TORACOCENTESIS: new FormControl('', Validators.required),
  APLI_MED: new FormControl('', Validators.required),
  RCP: new FormControl('', Validators.required),
  DESFIBRILADOR: new FormControl('', Validators.required),
  ASEPSIA: new FormControl('', Validators.required),
  APOSITO_OCLUSIVO: new FormControl('', Validators.required),
  APOSITO_ABULSIVO: new FormControl('', Validators.required),
  APOSITO_HUMEDO: new FormControl('', Validators.required),
  VENDAJE: new FormControl('', Validators.required),
  CURACION: new FormControl('', Validators.required),
  CONTROL_HEMORRAGIA: new FormControl('', Validators.required),
  INM_OBJ: new FormControl('', Validators.required),
  ATENCION_PARTO: new FormControl('', Validators.required),
  PSICOLOGIA: new FormControl('', Validators.required),
  CANALIZACION: new FormControl('', Validators.required),
  DEXTROSIS: new FormControl('', Validators.required),
  CANTIDAD: new FormControl('', Validators.required),
  OTROS_MANEJO: new FormControl('', Validators.required),
  SOLUCION_MANEJO: new FormControl('', Validators.required),
  MILILITROS_MANEJ: new FormControl('', Validators.required),
  CATETER: new FormControl('', Validators.required),
  FARMACO: new FormControl('', Validators.required),
  MEDICO_AUT: new FormControl('', Validators.required),
  MAT_CONSU: new FormControl('', Validators.required),
  NEUROLOGICO: new FormControl('', Validators.required),
  DIGESTIVO: new FormControl('', Validators.required),
  METABOLICO: new FormControl('', Validators.required),
  CARDIOVASCULAR: new FormControl('', Validators.required),
  ENDOCRINO: new FormControl('', Validators.required),
  OSTEOMUSCULAR: new FormControl('', Validators.required),
  RESPIRATORIO: new FormControl('', Validators.required),
  RENAL: new FormControl('', Validators.required),
  ALTER_EMOC: new FormControl('', Validators.required),
  UROGENITAL: new FormControl('', Validators.required),
  GINECO_OBS: new FormControl('', Validators.required),
  HEMATOLOGICO: new FormControl('', Validators.required),
  OTRO_CAUSA_C: new FormControl('', Validators.required),
  ESPECIFIQUE: new FormControl('', Validators.required),
  ANTECEDENTES_PAT: new FormControl('', Validators.required),
  LUGAR_VI: new FormControl('', Validators.required),
  UBICACION: new FormControl('', Validators.required),
  SERVICIO: new FormControl('', Validators.required)
   });

   constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router, private activerouter: ActivatedRoute,) { }

   ngOnInit(): void {
    this.id = this.activerouter.snapshot.paramMap.get('id');
    this.api.HospitalarioID(this.id).subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      localStorage.setItem('folio',this.activos[0].ID)


this.fecha=this.activos[0].FECHA
this.dia=this.activos[0].DIA
this.hora_salida=this.activos[0].HORA_SALIDA
this.hora_entrada=this.activos[0].HORA_ENTRADA
this.ambulancia=this.activos[0].AMBULANCIA
this.opera=this.activos[0].OPERADOR
this.bombe=this.activos[0].BOMBEROS
this.hora_tras=this.activos[0].HORA_TRASLADO
this.hora_arri=this.activos[0].HORA_ARRIBO
this.hora_entr=this.activos[0].HORA_ENTRADA
this.nombre=this.activos[0].NOMBRE
this.municip=this.activos[0].MUNICIPIO
this.sexo=this.activos[0].SEXO
this.afilia=this.activos[0].AFILIACION
this.domici=this.activos[0].DOMICILIO
this.tel=this.activos[0].TELEFONO
this.edad=this.activos[0].EDAD
this.otro=this.activos[0].OTRO

this.motivo=this.activos[0].MOTIVO
this.lugar=this.activos[0].LUGAR
this.conciencia=this.activos[0].CONCIENCIA
this.ocular=this.activos[0].OCULAR
this.verbal=this.activos[0].VERBAL
this.motora=this.activos[0].MOTORA
this.total=this.activos[0].TOTAL
this.piel=this.activos[0].PIEL
this.respira=this.activos[0].RESPIRA
this.hora=this.activos[0].HORA
this.fr=this.activos[0].FR
this.fc=this.activos[0].FC
this.temp=this.activos[0].TEMP
this.afilia=this.activos[0].AFILIA
this.ta=this.activos[0].TA
this.avdi=this.activos[0].AVDI
this.glasgow=this.activos[0].GLASGOW
this.glucosa=this.activos[0].GLUCOSA
this.pupilas=this.activos[0].PUPILAS
this.llen=this.activos[0].LLEN_CAP
this.so2=this.activos[0].SO2
this.alergias=this.activos[0].ALERGIAS
this.medica=this.activos[0].MEDICAMENTOS
this.enfe=this.activos[0].ENFE_Y_CIRUGIAS
this.ult_comida=this.activos[0].ULT_COMIDA
this.eventos_prev=this.activos[0].EVENTOS_PREV
this.triage=this.activos[0].TRIAGE
this.evaluacion=this.activos[0].EVALUACION_PRI
this.trata=this.activos[0].TRATAMIENTO
this.inm_cer=this.activos[0].INM_CERVICAL
this.collarin=this.activos[0].COLLARIN
this.inm_espina=this.activos[0].INM_ESPINAL
this.empaque=this.activos[0].EMPAQUETAMIENTO
this.orofa=this.activos[0].OROFARINGEA
this.aspirac=this.activos[0].ASPIRACION
this.ovace=this.activos[0].OVACE

this.oxige=this.activos[0].OXIGENACION
this.bvm=this.activos[0].BVM
this.nasa=this.activos[0].NASALES
this.feru=this.activos[0].FERULA
this.canu=this.activos[0].CANULA
this.toraco=this.activos[0].TORACOCENTESIS
this.apli_med=this.activos[0].APLI_MED
this.rcp=this.activos[0].RCP
this.desfi=this.activos[0].DESFIBRILADOR
this.asepsia=this.activos[0].ASEPSIA
this.oclusi=this.activos[0].APOSITO_OCLUSIVO
this.abulsi=this.activos[0].APOSITO_ABULSIVO
this.humed=this.activos[0].APOSITO_HUMEDO
this.afilia=this.activos[0].AFILIACION
this.venda=this.activos[0].VENDAJE
this.cura=this.activos[0].CURACION
this.control=this.activos[0].CONTROL_HEMORRAGIA
this.inm_obj=this.activos[0].INM_OBJ
this.parto=this.activos[0].ATENCION_PARTO
this.psico=this.activos[0].PSICOLOGIA
this.canaliz=this.activos[0].CANALIZACION
this.dextro=this.activos[0].DEXTROSIS
this.cant=this.activos[0].CANTIDAD
this.otros_manejo=this.activos[0].OTROS_MANEJO
this.solucion=this.activos[0].SOLUCION_MANEJO
this.mili=this.activos[0].MILILITROS_MANEJ
this.catet=this.activos[0].CATETER
this.farma=this.activos[0].FARMACO
this.medico=this.activos[0].MEDICO_AUT
this.mat=this.activos[0].MAT_CONSU
this.neuro=this.activos[0].NEUROLOGICO
this.diges=this.activos[0].DIGESTIVO
this.metabo=this.activos[0].METABOLICO
this.cardio=this.activos[0].CARDIOVASCULAR
this.endoc=this.activos[0].ENDOCRINO
this.osteo=this.activos[0].OSTEOMUSCULAR

this.respi=this.activos[0].RESPIRACION
this.renal=this.activos[0].RENAL
this.alter=this.activos[0].ALTER_EMOC
this.uroge=this.activos[0].UROGENITAL
this.gineco=this.activos[0].GINECO_OBS
this.hemat=this.activos[0].HEMATOLOGICO
this.otro_causa=this.activos[0].OTRO_CAUSA_C
this.especifi=this.activos[0].ESPECIFIQUE
this.antec=this.activos[0].ANTECEDENTES_PAT
this.lugar_vi=this.activos[0].LUGAR_VI
this.ubi=this.activos[0].UBICACION
this.serv=this.activos[0].SERVICIO


this.nuevoPC.setValue({
  'FECHA': this.fecha,
  'DIA': this.dia,
  'HORA_SALIDA': this.hora_salida,
  'HORA_ENTRADA': this.hora_entrada,
  'AMBULANCIA': this.ambulancia,
  'OPERADOR': this.opera,
  'BOMBEROS': this.bombe,
  'HORA_TRASLADO': this.hora_tras,
  'HORA_ARRIBO': this.hora_arri,
  'HORA_ENTREGA': this.hora_entr,
  'NOMBRE': this.nombre,
  'MUNICIPIO': this.municip,
  'SEXO': this.sexo,
  'AFILIACION': this.afilia,
  'DOMICILIO': this.domici,
  'TELEFONO': this.tel,
  'EDAD': this.edad,
  'OTRO': this.otro,
  'MOTIVO': this.motivo,
  'LUGAR': this.lugar,
  'CONCIENCIA': this.conciencia,
  'OCULAR': this.ocular,
  'VERBAL': this.verbal,
  'MOTORA': this.motora,
  'TOTAL': this.total,
  'PIEL': this.piel,
  'RESPIRACION': this.respi,
  'HORA': this.hora,
  'FR': this.fr,
  'FC': this.fc,
  'TEMP': this.temp,
  'TA': this.ta,
  'AVDI': this.avdi,
  'GLASGOW': this.glasgow,
  'GLUCOSA': this.glucosa,
  'PUPILAS': this.pupilas,
  'LLEN_CAP': this.llen,
  'SO2': this.so2,
  'ALERGIAS': this.alergias,
  'MEDICAMENTOS': this.medica,
  'ENFE_Y_CIRUGIAS': this.enfe,
  'ULT_COMIDA': this.ult_comida,
  'EVENTOS_PREV': this.eventos_prev,
  'TRIAGE': this.triage,
  'EVALUACION_PRI': this.evaluacion,
  'TRATAMIENTO': this.trata,
  'INM_CERVICAL': this.inm_cer,
  'COLLARIN': this.collarin,
  'INM_ESPINAL': this.inm_espina,
  'EMPAQUETAMIENTO': this.empaque,
  'OROFARINGEA': this.orofa,
  'ASPIRACION': this.aspirac,
  'OVACE': this.ovace,
  'OXIGENACION': this.oxige,
  'BVM': this.bvm,
  'NASALES': this.nasa,
  'FERULA': this.feru,
  'CANULA': this.canu,
  'TORACOCENTESIS': this.toraco,
  'APLI_MED': this.apli_med,
  'RCP': this.rcp,
  'DESFIBRILADOR': this.desfi,
  'ASEPSIA': this.asepsia,
  'APOSITO_OCLUSIVO': this.oclusi,
  'APOSITO_ABULSIVO': this.abulsi,
  'APOSITO_HUMEDO': this.humed,
  'VENDAJE': this.venda,
  'CURACION': this.cura,
  'CONTROL_HEMORRAGIA': this.fecha,
  'INM_OBJ': this.inm_obj,
  'ATENCION_PARTO': this.parto,
  'PSICOLOGIA': this.psico,
  'CANALIZACION': this.canaliz,
  'DEXTROSIS': this.dextro,
  'CANTIDAD': this.cant,
  'SOLUCION_MANEJO': this.solucion,
  'MILILITROS_MANEJ': this.mili,
  'CATETER': this.catet,
  'FARMACO': this.farma,
  'MEDICO_AUT': this.medico,
  'MAT_CONSU': this.mat,
  'NEUROLOGICO': this.neuro,
  'DIGESTIVO': this.diges,
  'METABOLICO': this.metabo,
  'CARDIOVASCULAR': this.cardio,
  'ENDOCRINO': this.endoc,
  'OSTEOMUSCULAR': this.osteo,
  'RESPIRATORIO': this.respi,
  'RENAL': this.renal,
  'ALTER_EMOC': this.alter,
  'UROGENITAL': this.uroge,
  'GINECO_OBS': this.gineco,
  'OTROS_MANEJO': this.otros_manejo,
  'HEMATOLOGICO': this.hemat,
  'OTRO_CAUSA_C': this.otro_causa,
  'ESPECIFIQUE': this.especifi,
  'ANTECEDENTES_PAT': this.antec,
  'LUGAR_VI': this.lugar_vi,
  'UBICACION': this.ubi,
  'SERVICIO': this.serv,





 })




    })






  }
  crearPDF() {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "cm",
      format: [ 21.5,33]

    });





    doc.setFontSize(12);

    doc.addFont('Courier', 'Arial', 'normal');
    doc.setFont('Courier');

    var logo = new Image();
    logo.src = 'assets/img/hospital.png';
    doc.addImage(logo, 'JPEG', 0, 0, 21.5, 33);
doc.text(this.nombre,2.9, 3.3  );
{
  doc.addFont('Courier', 'Arial', 'normal');
  doc.setFont('Courier');
  doc.setFontSize(12);

  doc.text(this.fecha ,3.50, 2.90);
  doc.text(this.dia ,7, 2.90);
  doc.text(this.hora_salida ,17.6, 2.90);
  doc.text(this.hora_entrada ,17.6, 3.5);
  doc.text(this.ambulancia ,3.1, 4.89);
  doc.text(this.opera ,2.52, 5.2);
  doc.text(this.bombe ,2,5.50);
  doc.text(this.hora_tras ,4.35,5.50);
  doc.text(this.hora_arri ,6.3,5.48);
  doc.text(this.hora_entr ,1.8,6.10);
  doc.text(this.nombre ,8.4,6.85);
  doc.text(this.municip ,10.5,1.1);
  doc.text(this.sexo ,10.5,1.6);


}


doc.save('Servicio-Hospitalario PART-1.pdf')



  }



  //Imprimir div
  imprimir(id: any) {



    printDiv(id);
  }


    variables(){
      if(this.ocular==1){
        this.ocular='Si'
        }else if(this.ocular==0){
          this.ocular='No'
        }

        if(this.verbal==0){
          this.verbal='No'
        }else if(this.verbal==1){
          this.verbal='Si'
        }

         if(this.motora==0){
          this.motora='No'
        }else if(this.motora==1){
          this.motora='Si'
        }

        if(this.total==0){
          this.total='No'
        }else if(this.total==1){
          this.total='Si'
        }

        if(this.inm_cer==0){
          this.inm_cer='No'
        }else if(this.inm_cer==1){
          this.inm_cer='Si'
        }

        if(this.collarin==0){
          this.collarin='No'
        }else if(this.collarin==1){
          this.collarin='Si'
        }

        if(this.inm_espina==0){
          this.inm_espina='No'
        }else if(this.inm_espina==1){
          this.inm_espina='Si'
        }
        if(this.empaque==0){
          this.empaque='No'
        }else if(this.empaque==1){
          this.empaque='Si'
        }
        if(this.orofa==0){
          this.orofa='No'
        }else if(this.orofa==1){
          this.orofa='Si'
        }
        if(this.aspirac==0){
          this.aspirac='No'
        }else if(this.aspirac==1){
          this.aspirac='Si'
        }


        if(this.ovace==0){
          this.ovace='No'
        }else if(this.ovace==1){
          this.ovace='Si'
        }
        if(this.oxige==0){
          this.oxige='No'
        }else if(this.oxige==1){
          this.oxige='Si'
        }
        if(this.bvm==0){
          this.bvm='No'
        }else if(this.bvm==1){
          this.bvm='Si'
        }
        if(this.nasa==0){
          this.nasa='No'
        }else if(this.nasa==1){
          this.nasa='Si'
        }

        if(this.feru==0){
          this.feru='No'
        }else if(this.feru==1){
          this.feru='Si'
        }

        if(this.canu==0){
          this.canu='No'
        }else if(this.canu==1){
          this.canu='Si'
        }
        if(this.toraco==0){
          this.toraco='No'
        }else if(this.toraco==1){
          this.toraco='Si'
        }
        if(this.apli_med==0){
          this.apli_med='No'
        }else if(this.apli_med==1){
          this.apli_med='Si'
        }

        if(this.neuro==0){
          this.neuro='No'
        }else if(this.neuro==1){
          this.neuro='Si'
        }

        if(this.diges==0){
          this.diges='No'
        }else if(this.diges==1){
          this.diges='Si'
        }

        if(this.metabo==0){
          this.metabo='No'
        }else if(this.metabo==1){
          this.metabo='Si'
        }

        if(this.cardio==0){
          this.cardio='No'
        }else if(this.cardio==1){
          this.cardio='Si'
        }

        if(this.endoc==0){
          this.endoc='No'
        }else if(this.endoc==1){
          this.endoc='Si'
        }

        if(this.osteo==0){
          this.osteo='No'
        }else if(this.osteo==1){
          this.osteo='Si'
        }

        if(this.respi==0){
          this.respi='No'
        }else if(this.respi==1){
          this.respi='Si'
        }

        if(this.renal==0){
          this.renal='No'
        }else if(this.renal==1){
          this.renal='Si'
        }

        if(this.alter==0){
          this.alter='No'
        }else if(this.alter==1){
          this.alter='Si'
        }

        if(this.uroge==0){
          this.uroge='No'
        }else if(this.uroge==1){
          this.uroge='Si'
        }

        if(this.gineco==0){
          this.gineco='No'
        }else if(this.gineco==1){
          this.gineco='Si'
        }

        if(this.hemat==0){
          this.hemat='No'
        }else if(this.hemat==1){
          this.hemat='Si'
        }

    }
 }
