import { Component } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-programa-anual',
  templateUrl: './programa-anual.component.html',
  styleUrls: ['./programa-anual.component.scss']
})
export class ProgramaAnualComponent {
  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docsSubsidio: any;
  boton: any;
  id: any;

  borrar: string[] = [];
  valor: any;
  todo: any;


  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };

  anotacionForm = new FormGroup({
    ID: new FormControl(''),
    ANOTACIONES: new FormControl('')
  })

  displayStyleEditarNombre = "none";
  constructor(private service: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {
    localStorage.setItem('fraccion', '101');
    localStorage.setItem('inciso', '101');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.service.obtenerA8(localStorage.getItem('fraccion'), localStorage.getItem('inciso')).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);

    })
  }
  checkCheckBoxvalue(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo() {
    this.todo = true;
    let valores = this.docs.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo = false;
    }
  }

  eliminarSeleccionados() {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          for (let numero of this.borrar) {
            console.log(numero);
            this.eliminarForm.ID = numero;
            this.eliminarForm.ESTATUS = 1;
            this.service.eliminarEnlace(this.eliminarForm).subscribe(
              (data: any) => {

              })
            this.alerts.alertaRealizado().then((res: any) => {
              location.reload()
            })

          }

        }

      })
  }

  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.service.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }

  openModalEditarNombre(ID: any) {
    this.id = ID;
    this.displayStyleEditarNombre = "block";
  }

  closePopUpNombre() {
    this.displayStyleEditarNombre = "none";
  }

  anotacion() {
    this.anotacionForm.value.ID = this.id;

    console.log(this.anotacionForm.value);
    this.service.editarDoc(this.anotacionForm.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.service.obtenerA8(localStorage.getItem('fraccion'), localStorage.getItem('inciso')).subscribe(res => {
        this.docs = res.body
        console.log(this.docs);

      })

    })


    this.closePopUpNombre();
  }

}
