<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">

    <section>
      <div class="container">



        <div class="row justify-content-center" style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753);">
          <div class="col-12 col-md-8 col-lg-8 col-xl-6">
            <form [formGroup]="nuevoPC" (ngSubmit)="postForm()" action="">
            <div class="row">
              <div class="col text-center">



                <img src="https://i.imgur.com/oGuTsJG.png" height="100" width="100" alt="" style="margin-top: 1vh;">


                <h1>Registro de Servicio</h1>
                <p style="margin-top: 1vh;" class="text-h3">Rellene los datos para completar su registro. </p>




              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Fecha:
                     </label>
                <input type="date"  class="form-control" placeholder="Fecha" formControlName="FECHA">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Folio:
                     </label>
                <input  class="form-control"  formControlName="FOLIO">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Cabinero:
                     </label>
                <input  class="form-control"  formControlName="CABINERO">
              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Reportante:
                     </label>
                <input  class="form-control"  formControlName="REPORTANTE">
              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de Recepción de Reporte:
                     </label>
                <input  class="form-control"  formControlName="HORA_RECEPCION">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de Salida:
                     </label>
                <input  class="form-control"  formControlName="HORA_SALIDA">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de Llegada al servicio:
                     </label>
                <input  class="form-control"  formControlName="HORA_LLEGADA">
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de término de servicio:
                     </label>
                <input  class="form-control"  formControlName="HORA_ARRIBO">
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Domicilio:
                     </label>
                <input  class="form-control"  formControlName="DOMICILIO">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Colonia:
                     </label>
                <input  class="form-control"  formControlName="COLONIA">
              </div>
            </div>

            <div style="justify-content: center;">
              <label for="" style="justify-content: center; text-align: center;margin-top: 2vh;color: #C20406; font-weight: bold;">- Datos de la persona atendida -</label>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col ">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Seleccione el número de personas:
                     </label>

                   <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                   name="" id="" placeholder="name@example.com" formControlName="NUM_PERSONAS">
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="1">1</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="2">2</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="3">3</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="4">4</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="5">5</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="6">6</option>



                 </select>
              </div>

<button type="button" (click)="generar()" style="border-radius: .8vh; background-color: #C20406; color: #F3F3F4;border-color: #C20406; border: solid;"> Generar</button>

            </div>





            <div class="row align-items-center mt-4" *ngIf="this.persona1===1 || this.persona2===1 || this.persona3===1 || this.persona4===1 ||this.persona5===1 ||this.persona6===1">
              <div class="col ">


                    <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                     Género:
                         </label>
                    <input  class="form-control"  formControlName="SEXO">

              </div>
            </div>



            <div class="row align-items-center mt-4" *ngIf="this.persona1==1 || this.persona2==1 || this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Edad:
                     </label>
                <input  class="form-control"   formControlName="EDAD">
              </div>
            </div>



            <hr style="color: #C20406;height: .4vh;"  *ngIf="this.persona1==1 || this.persona2==1 || this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1">

            <div class="row align-items-center mt-4" *ngIf=" this.persona2==1 || this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1">
              <div class="col ">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Género:
                      </label>
                 <input  class="form-control"  formControlName="SEXO1">
              </div>
            </div>



            <div class="row align-items-center mt-4" *ngIf="this.persona2==1 || this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Edad:
                     </label>
                <input  class="form-control"   formControlName="EDAD1">
              </div>
            </div>

            <hr style="color: #C20406; height: .4vh;" *ngIf="this.persona2==1 || this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1">


            <div class="row align-items-center mt-4" *ngIf=" this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1">
              <div class="col ">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Género:
                      </label>
                 <input  class="form-control" placeholder="Género"  formControlName="SEXO2">
              </div>
            </div>



            <div class="row align-items-center mt-4" *ngIf=" this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Edad:
                     </label>
                <input  class="form-control" placeholder="Género"   formControlName="EDAD2">
              </div>
            </div>

            <hr style="color: #C20406; height: .4vh;" *ngIf=" this.persona3==1 || this.persona4==1 ||this.persona5==1 ||this.persona6==1">



            <div class="row align-items-center mt-4" *ngIf="  this.persona4==1 ||this.persona5==1 ||this.persona6==1">
              <div class="col ">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Género:
                      </label>
                 <input  class="form-control" placeholder="Género"  formControlName="SEXO3">
              </div>
            </div>



            <div class="row align-items-center mt-4" *ngIf=" this.persona4==1 ||this.persona5==1 ||this.persona6==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Edad:
                     </label>
                <input  class="form-control"   formControlName="EDAD3">
              </div>
            </div>

            <hr style="color: #C20406; height: .4vh;" *ngIf=" this.persona4==1 ||this.persona5==1 ||this.persona6==1">



            <div class="row align-items-center mt-4" *ngIf=" this.persona5==1 ||this.persona6==1">
              <div class="col ">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Género:
                      </label>
                 <input  class="form-control" placeholder="Género"  formControlName="SEXO4">
              </div>
            </div>



            <div class="row align-items-center mt-4" *ngIf="this.persona5==1 ||this.persona6==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Edad:
                     </label>
                <input  class="form-control"   formControlName="EDAD4">
              </div>
            </div>

            <hr style="color: #C20406; height: .4vh;" *ngIf="this.persona5==1 ||this.persona6==1">


            <div class="row align-items-center mt-4" *ngIf=" this.persona6==1">
              <div class="col ">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Género:
                      </label>
                 <input  class="form-control" placeholder="Género"  formControlName="SEXO5">
              </div>
            </div>



            <div class="row align-items-center mt-4" *ngIf="this.persona6==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Edad:
                     </label>
                <input  class="form-control"   formControlName="EDAD5">
              </div>
            </div>

            <hr style="color: #C20406; height: .4vh;" *ngIf="this.persona6==1">


            <br><br>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
               Tipo de Servicio:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="SERVICIO">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Cobertura">Cobertura</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Eventos Jaripeos">Eventos Jaripeos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Desfiles Escolares">Desfiles Escolares</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Desfiles">Desfiles</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Civicos">Civicos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Deportivos">Deportivos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Religiosos">Religiosos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Escolares">Escolares</option>

                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Simulacros">Simulacros</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Accidente">Accidente</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo">Apoyo</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Atención">Atención</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Control">Control</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Dicatámenes">Dicatámenes</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Fugas">Fugas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Incendios">Incendios</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Inspecciones">Inspecciones</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Quemas">Quemas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Servicios">Servicios</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Local">Traslado Local</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Forane">Traslado Foraneo</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Verificacion">Verificacion</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Operativa">Operativa</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Cursos">Cursos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Servicios Varios">Servicios Varios</option>

              </select>

              </div>
            </div>

<br><br>
<hr>

            <div class="row align-items-center mt-4">
              <div class="col ">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Seleccione el número de vehículos:
                     </label>

                   <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                   name="" id="" placeholder="name@example.com" formControlName="NUM_VEHICULO">
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="1">1</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="2">2</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="3">3</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="4">4</option>
                   <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="5">5</option>




                 </select>
              </div>

              <div class="col" style="justify-content: start; text-align: start;">
                <button type="button" (click)="genVehiculo()" style="background-color: #869d9a00;border-color: #869d9a00; border: solid;" ><img width="50" height="50" src="https://img.icons8.com/ios/50/plus--v1.png" alt="plus--v1"/></button>
              </div>
            </div>
<hr>
            <div class="row align-items-center mt-4" *ngIf="this.vehiculo==1 || this.vehiculo1==1 || this.vehiculo2==1 || this.vehiculo3==1 || this.vehiculo4==1 ">
              <div class="col-8">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Vehículo:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="VEHICULO">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-008">PC-008</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-009">PC-009</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-010">PC-010</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-012">PC-012</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-014">PC-014</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-015">PC-015</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-016">PC-016</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-017">PC-017</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-018">PC-018</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-020">PC-020</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-021">PC-021</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-022">PC-022</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-023">PC-023</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-024">PC-024</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-025">PC-025</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-026">PC-026</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-027">PC-027</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-029">PC-029</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-030">PC-030</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-31">PC-31</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-32">PC-32</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-33">PC-33</option>
              </select>

              </div>


            </div>

<hr *ngIf="this.vehiculo==1 || this.vehiculo1==1 || this.vehiculo2==1 || this.vehiculo3==1 || this.vehiculo4==1 ">

            <div class="row align-items-center mt-4" *ngIf=" this.vehiculo1==1 || this.vehiculo2==1 || this.vehiculo3==1 || this.vehiculo4==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Vehículo:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="VEHICULO1">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-008">PC-008</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-009">PC-009</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-010">PC-010</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-012">PC-012</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-014">PC-014</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-015">PC-015</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-016">PC-016</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-017">PC-017</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-018">PC-018</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-020">PC-020</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-021">PC-021</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-022">PC-022</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-023">PC-023</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-024">PC-024</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-025">PC-025</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-026">PC-026</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-027">PC-027</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-029">PC-029</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-030">PC-030</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-31">PC-31</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-32">PC-32</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-33">PC-33</option>
              </select>

              </div>
            </div>

<hr *ngIf=" this.vehiculo1==1 || this.vehiculo2==1 || this.vehiculo3==1 || this.vehiculo4==1 ">

            <div class="row align-items-center mt-4" *ngIf=" this.vehiculo2==1 || this.vehiculo3==1 || this.vehiculo4==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Vehículo:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="VEHICULO2">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-008">PC-008</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-009">PC-009</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-010">PC-010</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-012">PC-012</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-014">PC-014</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-015">PC-015</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-016">PC-016</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-017">PC-017</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-018">PC-018</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-020">PC-020</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-021">PC-021</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-022">PC-022</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-023">PC-023</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-024">PC-024</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-025">PC-025</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-026">PC-026</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-027">PC-027</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-029">PC-029</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-030">PC-030</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-31">PC-31</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-32">PC-32</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-33">PC-33</option>
              </select>

              </div>
            </div>

<hr *ngIf=" this.vehiculo2==1 || this.vehiculo3==1 || this.vehiculo4==1 ">

            <div class="row align-items-center mt-4" *ngIf=" this.vehiculo3==1 || this.vehiculo4==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Vehículo:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="VEHICULO3">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-008">PC-008</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-009">PC-009</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-010">PC-010</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-012">PC-012</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-014">PC-014</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-015">PC-015</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-016">PC-016</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-017">PC-017</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-018">PC-018</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-020">PC-020</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-021">PC-021</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-022">PC-022</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-023">PC-023</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-024">PC-024</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-025">PC-025</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-026">PC-026</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-027">PC-027</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-029">PC-029</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-030">PC-030</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-31">PC-31</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-32">PC-32</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-33">PC-33</option>
              </select>

              </div>
            </div>

<hr *ngIf=" this.vehiculo3==1 || this.vehiculo4==1 ">

            <div class="row align-items-center mt-4" *ngIf=" this.vehiculo4==1 ">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Vehículo:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="VEHICULO4">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-002">PC-002</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-008">PC-008</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-009">PC-009</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-010">PC-010</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-012">PC-012</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-014">PC-014</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-015">PC-015</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-016">PC-016</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-017">PC-017</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-018">PC-018</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-020">PC-020</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-021">PC-021</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-022">PC-022</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-023">PC-023</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-024">PC-024</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-025">PC-025</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-026">PC-026</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-027">PC-027</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-029">PC-029</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-030">PC-030</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-31">PC-31</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-32">PC-32</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="PC-33">PC-33</option>
              </select>

              </div>
            </div>
<hr *ngIf=" this.vehiculo4==1 ">



<br><br>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Base:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="BASE">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="CENTRAL">CENTRAL</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="BASE 2 EJIDAL">BASE 2 EJIDAL</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="BASE 3 IPEVI">BASE 3 IPEVI</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="BASE 4 INFONAVIT">BASE 4 INFONAVIT</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="BASE 5 AZUCARERA">BASE 5 AZUCARERA</option>


              </select>

              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Conductor:
                     </label>
                <input  class="form-control"  formControlName="CONDUCTOR">
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Personal de Apoyo:
                     </label>
                <input  class="form-control"  formControlName="PERSONAL">
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Servicio Especifico:
                  </label>

                <select style="height: 6vh;border-radius: 4px;border-width: 1px;border-color: rgb(175, 175, 175);margin-left: 1vh;width: 23vh;margin-bottom: 2vh;"
                name="" id="" placeholder="name@example.com" formControlName="TIPO_SERVICIO">
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Dato no seleccionado">--Seleccionar--</option>
                <option  style="background-color: rgb(5, 54, 87);color: #F3F3F4;" selected value="Accidente Automovilistico">Accidente Automovilistico</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Accidente Bicicleta">Accidente Bicicleta</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Accidente Motocicleta">Accidente Motocicleta</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Accidente Multiple">Accidente Multiple</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Acordonamientos">Acordonamientos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Actos Civicos">Actos Civicos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Ahogamientos">Ahogamientos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo a Enfermo">Apoyo a Enfermo</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo Cortejo Funebre">Apoyo Cortejo Funebre</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo de Desfile">Apoyo de Desfile</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo de Eventos Deportivos">Apoyo de Eventos Deportivos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo jaripeos">Apoyo jaripeos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo Limpieza">Apoyo Limpieza</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyo Traslado Aereo">Apoyo Traslado Aereo</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Apoyos Varios">Apoyos Varios</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Arboles Caidos">Arboles Caidos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Atención en crisis Psicológicas">Atención en crisis Psicológicas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Atención a Enfermo">Atención a Enfermo</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Atención a Gineco Obstetrica">Atención a Gineco Obstetrica</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Atención a Lesionado">Atención a Lesionado</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Atención a Personas en Estado de Ebriedad">Atención a Personas en Estado de Ebriedad</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Atropellado">Atropellado</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Busquedas">Busquedas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Cables Caidos">Cables Caidos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Colmenas">Colmenas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Control Fauna Silvestre">Control Fauna Silvestre</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Control MAT Peligrosos">Control MAT Peligrosos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Corto Circuito">Corto Circuito</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Curaciones">Curaciones</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Curso,Practica">Curso,Practica</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Cursos Recibidos">Cursos Recibidos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Derrames">Derrames</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Derrumbes">Derrumbes</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Dictamenes de Riesgo">Dictamenes de Riesgo</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Dictamenes de Seguridad">Dictamenes de Seguridad</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Dictamenes de Vialidad">Dictamenes de Vialidad</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Domicilio Colapsado">Domicilio Colapsado</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Emjambre de Abejas">Emjambre de Abejas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Ejambre de Avispas y Panal">Ejambre de Avispas y Panal</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Falsa Alarma">Falsa Alarma</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Fuga de Gas">Fuga de Gas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Fugas de Agua">Fugas de Agua</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Incendio Industrial">Incendio Industrial</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Incendio Vehiculos">Incendio Vehiculos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Incendios Contenedor">Incendios Contenedor</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Incendios de Pastizal">Incendios de Pastizal</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Incendios Diversos">Incendios Diversos</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Inspecciones">Inspecciones</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Investigación de Incendio">Investigación de Incendio</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Intento de Suicidio">Intento de Suicidio</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Inundación">Inundación</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Occiso">Occiso</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Occiso Covid-19">Occiso Covid-19</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Postes Dañados">Postes Dañados</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Quema de Basura">Quema de Basura</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Rescates">Rescates</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Reuniones">Reuniones</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Rondas de Vigilancia">Rondas de Vigilancia</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Semovientes">Semovientes</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Servicio sin Efecto">Servicio sin Efecto</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Simulacros">Simulacros</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Suicidio">Suicidio</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Traslado Foraneo">Traslado Foraneo</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Traslado Local">Traslado Local</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Verificación Abejas y Avispas">Verificación Abejas y Avispas</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Verificación de Daños">Verificación de Daños</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Verificación Incendios">Verificación Incendios</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Verificación Escolar">Verificación Escolar</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Volcaduras">Volcaduras</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Operativo Especial">Operativo Especial</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Verificaciones Generales">Verificaciones Generales</option>
                <option style="background-color: rgb(5, 54, 87);color: #F3F3F4;" value="Verificaciones de Dictamen o Inspección">Verificaciones de Dictamen o Inspección</option>


              </select>

              </div>
            </div>




            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Lugar de incidente:
                     </label>
                <input  class="form-control"  formControlName="LUGAR">
              </div>
            </div>


            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione la
              imagen a subir</label>
            <br><input type="file" style="margin-top: 1%;" (change)="contarArchivos($event)" formControlName="IMAGEN"
              multiple>

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>



            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione la
              imagen a subir</label>
            <br><input type="file" style="margin-top: 1%;" (change)="contarArchivos1($event)" formControlName="IMAGEN2"
              multiple>

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento1 && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento1"></div>


            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione la
              imagen a subir</label>
            <br><input type="file" style="margin-top: 1%;" (change)="contarArchivos2($event)" formControlName="IMAGEN3"
              multiple>

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento2 && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento2"></div>




            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Descripción del Reporte:
                     </label><br>
                <textarea style="width: 100%;border-radius: 1vh;" name="" id="" cols="30" rows="10" formControlName="DESCRIPCION"></textarea>
              </div>
            </div>






            <div class="row justify-content-start mt-4">
              <div class="col">

                <button type="submit"  class="btn  mt-4" style="width: 100%;margin-bottom: 5vh;background-color: #C20406;color: rgb(255, 255, 255);">Enviar</button>
              </div>
            </div>



            <div>


            </div>




          </form>
          </div>
        </div>
      </div>
    </section>



  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
