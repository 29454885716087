





<div class="animate__animated animate__jackInTheBox" style="text-align: center; justify-content: center;margin-top: 15vh;">
  <strong  style="font-size: 4vh;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">------------</strong>
</div>


<section class="photos-section py-5 d-flex justify-content-center" >
  <div class="container">
    <div class="row" >



      <div class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse" style="text-align: center; justify-content: center;"data-aos="fade-up">
        <a href="admin/proteccion-civil/pc/interno/ficha-pago" class="card-title">
          <div class="card ">
            <div class="position-relative" style="text-align: center; justify-content: center;">
              <img style="margin-top: 2vh;" height="250" width="100%" src="https://i.imgur.com/TUbWvTD.png"
                class="card-img-top " alt="">
                <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                  <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                </svg>
            </div>
            <div class="card-body">

              <strong>Trámite</strong>

            </div>
          </div>
        </a>
      </div>


      <div class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse" style="text-align: center; justify-content: center;"data-aos="fade-up">
        <a href="admin/lugares-de-interes/catedral" class="card-title">
          <div class="card ">
            <div class="position-relative" style="text-align: center; justify-content: center;">
              <img style="margin-top: 2vh;" height="250" width="100%" src="https://i.imgur.com/Frfzeg0.png"
                class="card-img-top " alt="">
                <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                  <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                </svg>
            </div>
            <div class="card-body">

              <strong>Inscripción</strong>

            </div>
          </div>
        </a>
      </div>



      <div class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse" style="text-align: center; justify-content: center;"data-aos="fade-up">
        <a href="admin/lugares-de-interes/catedral" class="card-title">
          <div class="card ">
            <div class="position-relative" style="text-align: center; justify-content: center;">
              <img style="margin-top: 2vh;" height="250" width="100%" src="https://i.imgur.com/I3gQhTc.png"
                class="card-img-top " alt="">
                <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                  <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                </svg>
            </div>
            <div class="card-body">

              <strong>Descarga de constancias</strong>

            </div>
          </div>
        </a>
      </div>















