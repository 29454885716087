import { Component } from '@angular/core';

@Component({
  selector: 'app-casa-familia',
  templateUrl: './casa-familia.component.html',
  styleUrls: ['./casa-familia.component.scss']
})
export class CasaFamiliaComponent {

}
