import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-busqueda-empresa',
  templateUrl: './busqueda-empresa.component.html',
  styleUrls: ['./busqueda-empresa.component.scss']
})
export class BusquedaEmpresaComponent {

  documento: any;
  token: any;
  var: any

  constructor(private api: GeneralService, private router: Router, private alertas: SweetAlertService) { }

  nuevoPC = new FormGroup({
    EMPRESA: new FormControl('', Validators.required),

  });


  ngOnInit() {




  }


  postForm() {
    this.var = this.nuevoPC.value.EMPRESA
    console.log(this.var);

    this.api.filtroEmpresa(this.var).subscribe(res => {
      this.documento = res.body;
      localStorage.setItem('empresa',this.var)
      console.log(this.documento);

      if (res.body.length === 0) {
        this.alertas.alertaError('Error en la busqueda', 'Empresa no encontrada.')

      }else{

this.router.navigate(['admin/proteccion-civil/pc/interno/lista/registros'])
      }



    })



  }





}
