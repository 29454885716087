import { Component } from '@angular/core';

@Component({
  selector: 'app-slect-servicio',
  templateUrl: './slect-servicio.component.html',
  styleUrls: ['./slect-servicio.component.scss']
})
export class SlectServicioComponent {

}
