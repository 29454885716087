import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-escuela',
  templateUrl: './escuela.component.html',
  styleUrls: ['./escuela.component.scss']
})
export class EscuelaComponent {

  razon:any
  concepto:any
  cantidad:any
  solicitante:any
   archivos: string[] = [];
 estatus:any

//menu de registro
auxilio:any
calendario:any
capacitacion:any
comunicaciones:any
directorio:any
organizacion:any
preventivo:any
proposito:any
recursosh:any
riesgo:any
riesgos:any
datos:any
token:any
idBrigada:any
docs:any

   //Variables para url de archivos
   urlDocumento = '';



   //Variables para indicar carga de archivos
   subiendoDocumento = false;



   programa = new FormGroup({
    ID: new FormControl(''),
     PROGRAMA: new FormControl('', Validators.required),
     DESCRIPCION: new FormControl('', Validators.required),
     CONSTANCIA: new FormControl('', Validators.required)
   });
   descripcion = new FormGroup({
    ID: new FormControl(''),
    PROGRAMA: new FormControl('', Validators.required),
    DESCRIPCION: new FormControl('', Validators.required),
    CONSTANCIA: new FormControl('', Validators.required)
   });

   constancia = new FormGroup({
    ID: new FormControl(''),
    PROGRAMA: new FormControl('', Validators.required),
    DESCRIPCION: new FormControl('', Validators.required),
    CONSTANCIA: new FormControl('', Validators.required)
   });



   constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,private storage: Storage,) { }



   ngOnInit(): void {
if(localStorage.getItem('token')){
this.token=true
}


      this.api.obtenerEscuelas().subscribe(res => {
        this.docs = res.body
        console.log(this.docs);

      })
   }



   programaBrigadista() {
    console.log('entra');
this.programa.value.ID='1'
console.log(this.programa.value);

    this.api.editarEscuela(this.programa.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.api.obtenerEscuelas().subscribe(res => {
        this.docs = res.body
        console.log(this.docs);

      })

    })

  }

 descripcionBrigadista() {
    console.log('entra');
this. descripcion.value.ID='3'
console.log(this. descripcion.value);

    this.api.editarEscuela(this.descripcion.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.api.obtenerEscuelas().subscribe(res => {
        this.docs = res.body
        console.log(this.docs);

      })

    })

  }

 constanciaBrigadista() {
    console.log('entra');
this.constancia.value.ID='2'
console.log(this.constancia.value);

    this.api.editarEscuela(this.constancia.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.api.obtenerEscuelas().subscribe(res => {
        this.docs = res.body
        console.log(this.docs);

      })

    })

  }



  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })



   async changeFileMenu(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);

          this.programa.value.PROGRAMA = file;
          console.log(this.programa.value.PROGRAMA);

        }).catch(error => { console.log(error) });

      })
    })

  }


}
