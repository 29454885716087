<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">




    <section class="photos-section py-5 d-flex justify-content-center" style="margin-top: 30vh;">
      <div class="container">
        <div class="row" >

          <div class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse"data-aos="fade-up">
            <a href="admin/proteccion-civil/nuevo/servicio" class="card-title">
              <div class="card ">
                <div class="position-relative">
                  <img style="margin-top: 2vh;border-radius: 1vh;" src="https://i.imgur.com/3FfBHo5.png"
                    class="card-img-top " alt="">
                    <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                      <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                    </svg>
                </div>
                <div class="card-body">

                  <strong>Registrar Nuevo Servicio <img width="30" height="30" src="https://i.imgur.com/z4rXkQO.png" alt="youtube"/></strong>

                </div>
              </div>
            </a>
          </div>

          <div class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse"data-aos="fade-up">
            <a href="admin/proteccion-civil/seleccionar/servicio" class="card-title">
              <div class="card ">
                <div class="position-relative">
                  <img style="margin-top: 2vh; border-radius: 1vh;" src="https://i.imgur.com/tL3oGaM.png"
                    class="card-img-top " alt="">
                    <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                      <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                    </svg>
                </div>
                <div class="card-body">

                  <strong> Hospitalario <img width="30" height="30" src="https://i.imgur.com/z4rXkQO.png" alt="youtube"/></strong>

                </div>
              </div>
            </a>
          </div>

          <div class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse" style="text-align: center; justify-content: center;"data-aos="fade-up">
            <a href="admin/proteccion-civil/servicios/gestion" class="card-title">
              <div class="card ">
                <div class="position-relative" style="text-align: center; justify-content: center;">
                  <img style="margin-top: 2vh;border-radius: 1vh;" src="https://i.imgur.com/O93KKYK.png"
                    class="card-img-top " alt="">
                    <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                      <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                    </svg>
                </div>
                <div class="card-body">

                  <strong>Administración de Servicios <img width="30" height="30" src="https://i.imgur.com/z4rXkQO.png" alt="youtube"/></strong>

                </div>
              </div>
            </a>
          </div>


        </div>
      </div>
    </section>










  </main><!-- End #main -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
