import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
declare const printDiv: any;
@Component({
  selector: 'app-hospitalario-id-part2',
  templateUrl: './hospitalario-id-part2.component.html',
  styleUrls: ['./hospitalario-id-part2.component.scss']
})
export class HospitalarioIdPart2Component {
  boton: any;
  id: any; data: any; areas: any; area: any; subarea: any; areas1: any; menu: any; titulo: any; icono: any; archivos: string[] = []; subscribeTimer: any;animation = true;cuerpo:any; ids:any;
activos:any;  fecha:any;  dia:any;
num_folio:any
folio:any
arma:any
caida:any
volca:any
golpe:any
colicion:any
atro:any
animal:any
punza:any
human:any
fuego:any
explo:any
explora:any
toxic:any
elec:any
proyec:any
maqui:any
liqui:any
lesion:any
pupi:any
gesta:any
cesa:any
partos:any
abortos:any
fecha_parto:any
semanas:any
menbra:any
integra:any
sangrado:any
contra:any
frecu:any
dura:any
hora_naci:any
produ:any
sexo:any
lugar_VI:any
lugar_VII:any
place:any
apgar:any
rcp:any
silver:any
oxige:any
dsp:any
cruz:any
pf:any
pd:any
sec:any
pgr:any
mp:any
o_VII:any
o_viii:any
uni1:any
uni2:any
uni3:any
perso:any
perso2:any
perso3:any
agent:any
ced:any
tipo:any
tipo1:any
tipo2:any
tipo3:any
color1:any
color2:any
color3:any
marca1:any
marca2:any
marca3:any
placas1:any
placas2:any
placas3:any
lesio1:any
lesio2:any
lesio3:any



  nuevoPC2 = new FormGroup({
    LUGAR_VI: new FormControl('', Validators.required),
    FOLIO: new FormControl('', Validators.required),
    ARMA_BLANCA: new FormControl('', Validators.required),
    CAIDA: new FormControl('', Validators.required),
    VOLCADURA: new FormControl('', Validators.required),
    GOLPE: new FormControl('', Validators.required),
    COLICION: new FormControl('', Validators.required),
    ATROPELLAMIENTO: new FormControl('', Validators.required),
    ANIMAL: new FormControl('', Validators.required),
    PUNZANTE: new FormControl('', Validators.required),
    HUMANO: new FormControl('', Validators.required),

    FUEGO: new FormControl('', Validators.required),
    EXPLOSION: new FormControl('', Validators.required),
    EXPLORACION: new FormControl('', Validators.required),
    TOXICA: new FormControl('', Validators.required),
    ELECTRICIDAD: new FormControl('', Validators.required),
    PROYECTIL: new FormControl('', Validators.required),
    MAQUINARIA: new FormControl('', Validators.required),
    LIQUIDOS: new FormControl('', Validators.required),
    LESION: new FormControl('', Validators.required),
    PUPILAS: new FormControl('', Validators.required),

    GESTA: new FormControl('', Validators.required),
    CESAREAS: new FormControl('', Validators.required),
    PARTOS: new FormControl('', Validators.required),
    ABORTOS: new FormControl('', Validators.required),
    FECHA_PARTO: new FormControl('', Validators.required),
    SEMANAS: new FormControl('', Validators.required),
    MEMBRANA_ROTA: new FormControl('', Validators.required),
    INTEGRA: new FormControl('', Validators.required),
    SANGRADO: new FormControl('', Validators.required),
    CONTRACCIONES: new FormControl('', Validators.required),
    FRECUENCIA: new FormControl('', Validators.required),
    DURACION: new FormControl('', Validators.required),
    HORA_NACIMIENTO: new FormControl('', Validators.required),
    PRODUCTO: new FormControl('', Validators.required),
    SEXO: new FormControl('', Validators.required),
    LUGAR_VII: new FormControl('', Validators.required),
    PLACENTA_EXPULSADA: new FormControl('', Validators.required),
    A_P_G_A_R: new FormControl('', Validators.required),
    RCP_VII: new FormControl('', Validators.required),
    SILVERMAN: new FormControl('', Validators.required),
    OXIGENOTERAPIA: new FormControl('', Validators.required),

    DSP: new FormControl('', Validators.required),
    CRUZ_ROJA: new FormControl('', Validators.required),
    PF: new FormControl('', Validators.required),
    SEC_MOVILIDAD: new FormControl('', Validators.required),
    PGR: new FormControl('', Validators.required),
    MP: new FormControl('', Validators.required),
    OTROS_VII: new FormControl('', Validators.required),
    OTRO_VIII: new FormControl('', Validators.required),
    UNIDADES1: new FormControl('', Validators.required),
    UNIDADES2: new FormControl('', Validators.required),
    UNIDADES3: new FormControl('', Validators.required),
    PERSONAL: new FormControl('', Validators.required),
    PERSONAL2: new FormControl('', Validators.required),
    PERSONAL3: new FormControl('', Validators.required),
    AGENTE: new FormControl('', Validators.required),
    CED_PROFESIONAL: new FormControl('', Validators.required),
    TIPO: new FormControl('', Validators.required),
    TIPO1: new FormControl('', Validators.required),
    TIPO2: new FormControl('', Validators.required),

    TIPO3: new FormControl('', Validators.required),
    COLOR1: new FormControl('', Validators.required),
    COLOR2: new FormControl('', Validators.required),
    COLOR3: new FormControl('', Validators.required),
    MARCA1: new FormControl('', Validators.required),
    MARCA2: new FormControl('', Validators.required),
    MARCA3: new FormControl('', Validators.required),
    PLACAS1: new FormControl('', Validators.required),
    PLACAS2: new FormControl('', Validators.required),
    PLACAS3: new FormControl('', Validators.required),

    LESIONADOS1: new FormControl('', Validators.required),
    LESIONADOS2: new FormControl('', Validators.required),
    LESIONADOS3: new FormControl('', Validators.required),

  });

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

  ngOnInit(): void {
this.num_folio= localStorage.getItem('folio')
    this.api.HospitalarioID2(this.num_folio).subscribe(res => {
      this.activos = res.body
      console.log(this.activos);

      this.arma=this.activos[0].ARMA_BLANCA
      this.caida=this.activos[0].CAIDA
      this.volca=this.activos[0].VOLCADURA
      this.golpe=this.activos[0].GOLPE
      this.colicion=this.activos[0].COLICION
      this.atro=this.activos[0].ATROPELLAMIENTO
      this.animal=this.activos[0].ANIMAL
      this.abortos=this.activos[0].ABORTOS
      this.punza=this.activos[0].PUNZANTE
      this.human=this.activos[0].HUMANO
      this.fuego=this.activos[0].FUEGO
      this.explora=this.activos[0].EXPLORACION
      this.explo=this.activos[0].EXPLOSION
      this.toxic=this.activos[0].TOXICA
      this.elec=this.activos[0].ELECTRICIDAD
      this.proyec=this.activos[0].PROYECTIL
      this.maqui=this.activos[0].MAQUINARIA
      this.liqui=this.activos[0].LIQUIDOS
      this.lesion=this.activos[0].LESION
      this.fecha_parto=this.activos[0].FECHA_PARTO

      this.pupi=this.activos[0].PUPILAS
      this.gesta=this.activos[0].GESTA
      this.cesa=this.activos[0].CESAREAS
      this.partos=this.activos[0].PARTOS
      this.semanas=this.activos[0].SEMANAS
      this.menbra=this.activos[0].MEMBRANA_ROTA
      this.integra=this.activos[0].INTEGRA
      this.sangrado=this.activos[0].SANGRADO
      this.contra=this.activos[0].CONTRACCIONES
      this.frecu=this.activos[0].FRECUENCIA
      this.dura=this.activos[0].DURACION
      this.hora_naci=this.activos[0].HORA_NACIMIENTO
      this.produ=this.activos[0].PRODUCTO
      this.sexo=this.activos[0].SEXO
      this.lugar_VII=this.activos[0].LUGAR_VII
      this.lugar_VI=this.activos[0].LUGAR_VI
      this.place=this.activos[0].PLACENTA_EXPULSADA
      this.apgar=this.activos[0].A_P_G_A_R
      this.rcp=this.activos[0].RCP_VII
      this.silver=this.activos[0].SILVERMAN
      this.oxige=this.activos[0].OXIGENOTERAPIA
      this.dsp=this.activos[0].DSP
      this.cruz=this.activos[0].CRUZ_ROJA
      this.pf=this.activos[0].PF
      this.sec=this.activos[0].SEC_MOVILIDAD
      this.pgr=this.activos[0].PGR
      this.mp=this.activos[0].MP
      this.o_VII=this.activos[0].OTROS_VII
      this.o_viii=this.activos[0].OTRO_VIII
      this.uni1=this.activos[0].UNIDADES1
      this.uni2=this.activos[0].UNIDADES2
      this.uni3=this.activos[0].UNIDADES3
      this.perso=this.activos[0].PERSONAL
      this.perso2=this.activos[0].PERSONAL2
      this.perso3=this.activos[0].PERSONAL3
      this.agent=this.activos[0].AGENTE
      this.ced=this.activos[0].CED_PROFESIONAL

      this.tipo=this.activos[0].TIPO
      this.tipo1=this.activos[0].TIPO1
      this.tipo2=this.activos[0].TIPO2
      this.tipo3=this.activos[0].TIPO3
      this.color1=this.activos[0].COLOR1
      this.color2=this.activos[0].COLOR2
      this.color3=this.activos[0].COLOR3
      this.marca1=this.activos[0].MARCA1
      this.marca2=this.activos[0].MARCA2
      this.marca3=this.activos[0].MARCA3
      this.placas1=this.activos[0].PLACAS1
      this.placas2=this.activos[0].PLACAS2
      this.placas3=this.activos[0].PLACAS3
      this.lesio1=this.activos[0].LESIONADOS1
      this.lesio2=this.activos[0].LESIONADOS2
      this.lesio3=this.activos[0].LESIONADOS3




      this.nuevoPC2.setValue({
        'LUGAR_VI': this.lugar_VI,
        'FOLIO': this.num_folio,
        'ARMA_BLANCA': this.arma,
        'CAIDA': this.caida,
        'VOLCADURA': this.volca,
        'GOLPE': this.golpe,
        'COLICION': this.colicion,
        'ATROPELLAMIENTO': this.atro,
        'ANIMAL': this.animal,
        'PUNZANTE': this.punza,
        'HUMANO': this.human,
        'FUEGO': this.fuego,
        'EXPLOSION': this.explo,
        'EXPLORACION': this.explora,
        'TOXICA': this.toxic,
        'ELECTRICIDAD': this.elec,
        'PROYECTIL': this.proyec,
        'MAQUINARIA': this.maqui,
        'LIQUIDOS': this.liqui,
        'LESION': this.lesion,
        'PUPILAS': this.pupi,
        'GESTA': this.gesta,
        'CESAREAS': this.cesa,
        'PARTOS': this.partos,
        'ABORTOS': this.abortos,
        'FECHA_PARTO': this.fecha_parto,
        'SEMANAS': this.semanas,
        'MEMBRANA_ROTA': this.menbra,
        'INTEGRA': this.integra,
        'SANGRADO': this.sangrado,
        'CONTRACCIONES': this.contra,
        'FRECUENCIA': this.frecu,
        'DURACION': this.dura,
        'HORA_NACIMIENTO': this.hora_naci,
        'PRODUCTO': this.produ,
        'SEXO': this.sexo,
        'LUGAR_VII': this.lugar_VII,
        'PLACENTA_EXPULSADA': this.place,
        'A_P_G_A_R': this.apgar,
        'RCP_VII': this.rcp,
        'SILVERMAN': this.silver,
        'OXIGENOTERAPIA': this.oxige,
        'DSP': this.dsp,
        'CRUZ_ROJA': this.cruz,
        'PF': this.pf,
        'SEC_MOVILIDAD': this.sec,
        'PGR': this.pgr,
        'MP': this.mp,
        'OTROS_VII': this.o_VII,
        'OTRO_VIII': this.o_viii,
        'UNIDADES1': this.uni1,
        'UNIDADES2': this.uni2,
        'UNIDADES3': this.uni3,
        'PERSONAL': this.perso,
        'PERSONAL2': this.perso2,
        'PERSONAL3': this.perso3,
        'AGENTE': this.agent,
        'CED_PROFESIONAL': this.ced,
        'TIPO': this.tipo,
        'TIPO1': this.tipo1,
        'TIPO2': this.tipo2,
        'TIPO3': this.tipo3,
        'COLOR1': this.color1,
        'COLOR2': this.color2,
        'COLOR3': this.color3,
        'MARCA1': this.marca1,
        'MARCA2': this.marca2,
        'MARCA3': this.marca3,
        'PLACAS1': this.placas1,
        'PLACAS2': this.placas2,
        'PLACAS3': this.placas3,
        'LESIONADOS1': this.lesio1,
        'LESIONADOS2': this.lesio2,
        'LESIONADOS3': this.lesio3,




       })




 })








  }



  imprimir(id: any) {



    printDiv(id);
  }


  variables(){
    if(this.caida==1){
      this.caida='Si'
      }else if(this.caida==0){
        this.caida='No'
      }

      if(this.golpe==0){
        this.golpe='No'
      }else if(this.golpe==1){
        this.golpe='Si'
      }

       if(this.volca==0){
        this.volca='No'
      }else if(this.volca==1){
        this.volca='Si'
      }

      if(this.arma==0){
        this.arma='No'
      }else if(this.arma==1){
        this.arma='Si'
      }

      if(this.colicion==0){
        this.colicion='No'
      }else if(this.colicion==1){
        this.colicion='Si'
      }

      if(this.atro==0){
        this.atro='No'
      }else if(this.atro==1){
        this.atro='Si'
      }

      if(this.animal==0){
        this.animal='No'
      }else if(this.animal==1){
        this.animal='Si'
      }
      if(this.punza==0){
        this.punza='No'
      }else if(this.punza==1){
        this.punza='Si'
      }
      if(this.human==0){
        this.human='No'
      }else if(this.human==1){
        this.human='Si'
      }
      if(this.fuego==0){
        this.fuego='No'
      }else if(this.fuego==1){
        this.fuego='Si'
      }


      if(this.explo==0){
        this.explo='No'
      }else if(this.explo==1){
        this.explo='Si'
      }
      if(this.toxic==0){
        this.toxic='No'
      }else if(this.toxic==1){
        this.toxic='Si'
      }
      if(this.elec==0){
        this.elec='No'
      }else if(this.elec==1){
        this.elec='Si'
      }
      if(this.proyec==0){
        this.proyec='No'
      }else if(this.proyec==1){
        this.proyec='Si'
      }

      if(this.maqui==0){
        this.maqui='No'
      }else if(this.maqui==1){
        this.maqui='Si'
      }

      if(this.liqui==0){
        this.liqui='No'
      }else if(this.liqui==1){
        this.liqui='Si'
      }
      if(this.gesta==0){
        this.gesta='No'
      }else if(this.gesta==1){
        this.gesta='Si'
      }
      if(this.cesa==0){
        this.cesa='No'
      }else if(this.cesa==1){
        this.cesa='Si'
      }

      if(this.partos==0){
        this.partos='No'
      }else if(this.partos==1){
        this.partos='Si'
      }

      if(this.abortos==0){
        this.abortos='No'
      }else if(this.abortos==1){
        this.abortos='Si'
      }

      if(this.fecha_parto==0){
        this.fecha_parto='No'
      }else if(this.fecha_parto==1){
        this.fecha_parto='Si'
      }

      if(this.semanas==0){
        this.semanas='No'
      }else if(this.semanas==1){
        this.semanas='Si'
      }

      if(this.menbra==0){
        this.menbra='No'
      }else if(this.menbra==1){
        this.menbra='Si'
      }

      if(this.sangrado==0){
        this.sangrado='No'
      }else if(this.sangrado==1){
        this.sangrado='Si'
      }

      if(this.contra==0){
        this.contra='No'
      }else if(this.contra==1){
        this.contra='Si'
      }

      if(this.produ==0){
        this.produ='No'
      }else if(this.produ==1){
        this.produ='Si'
      }

      if(this.rcp==0){
        this.rcp='No'
      }else if(this.rcp==1){
        this.rcp='Si'
      }

      if(this.dsp==0){
        this.dsp='No'
      }else if(this.dsp==1){
        this.dsp='Si'
      }

      if(this.cruz==0){
        this.cruz='No'
      }else if(this.cruz==1){
        this.cruz='Si'
      }

      if(this.pf==0){
        this.pf='No'
      }else if(this.pf==1){
        this.pf='Si'
      }

      if(this.sec==0){
        this.sec='No'
      }else if(this.sec==1){
        this.sec='Si'
      }

      if(this.pgr==0){
        this.pgr='No'
      }else if(this.pgr==1){
        this.pgr='Si'
      }

      if(this.mp==0){
        this.mp='No'
      }else if(this.mp==1){
        this.mp='Si'
      }

  }



}
