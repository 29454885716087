<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>


  <section style="background-color: rgba(226, 226, 226, 0.617);margin-top: 100px;" id="services"
  class="services section-bg"  >




      <div class="row justify-content-center" style="  background-color: rgba(226, 226, 226, 0.617); margin-top: 5vh; ">
        <div class="col-12 col-md-8 col-lg-8 col-xl-8">
          <div style="border-radius: 3vh; background-color: rgb(255, 255, 255);" >

              <div class="row">

                <div class="col-12">
                  <img class="d-none d-sm-block d-md-block" src="https://i.imgur.com/M3FWcH3.png" alt="">
                  <h3
                    style="text-align: center;font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; margin-top: 2vh; color: rgb(5, 62, 168); background-color: rgb(255, 255, 255);">
                    Bienvenid@ a la ventana de conexión a nuestras redes sociales <br> Siguenos y obten noticias de interés</h3>
                </div>
              </div>



              <img src="https://i.imgur.com/e4fVaR2.png" style="width: 100%;" alt="">
              <div class="row">
                <div class="col-12">

                          <ul class="list-group">
                            <li class="list-group-item" > <input id="face"  alt="" style="font-weight: bold;"> <a  target="_blank" href="https://www.facebook.com/GobiernodeAutlan/ "> Gobierno de Autlán</a> </li>
                            <li class="list-group-item"><input id="tw"  alt="" style="font-weight: bold;">  <a  target="_blank" href=" https://twitter.com/GobiernoAutlan ">Gobierno de Autlán</a> </li>
                            <li class="list-group-item"> <input id="yout"  alt="" style="font-weight: bold;">  <a  target="_blank" href="https://www.youtube.com/channel/UCCukDOtB-8M-VWL7gJZK01Q">Gobierno de Autlán</a></li>
                            <li class="list-group-item"> <input id="tik"  alt="" style="font-weight: bold;"><a  target="_blank" href="https://www.tiktok.com/@gobiernodeautlan?is_from_webapp=1&sender_device=pc"> Gobierno de Autlán</a> </li>
                            <li class="list-group-item"> <input id="insta"  alt="" style="font-weight: bold;"><a  target="_blank"  href="https://www.instagram.com/gobiernodeautlan/">Gobierno de Autlán</a> </li>
                            <li class="list-group-item"> <img height="35" width="35" style="margin-left: .7vh;border-radius: 1vh" src="https://i.imgur.com/RcsGVyv.jpg" alt=""><a  target="_blank" href="https://api.nirecloud.com/app/7"> Gobierno de Autlán</a>  </li>
                          </ul>

                </div>
              </div>



            </div>


        </div>
      </div>

  </section>






  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
