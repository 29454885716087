

<section style="background-color:  #F3F3F4; height: 6vh; width: 100%;"></section>

<div class="wrapper">
  <section style="background-color: white; padding-left: 2vh; padding-right: 2vh; overflow: scroll;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">
        <div class="row">
          <div style="justify-content: left; display: flex; width: 100%;" class="col-sm-2">
            <button (click)="reporte()" class="boton">Descargar tabla</button>
          </div>
          <div style="justify-content: right; display: flex; width: 100%;" class="col-sm-10">
            <select name="filtro" id="filtro" #cbCampo
              style="border-top-left-radius: 7px; border-bottom-left-radius: 7px; height: 30px; margin-top: 2vh; border-color: black;">
              <option selected value="ID">FOLIO</option>
              <option selected value="EDAD">EDAD</option>
              <option value="GENERO">GENERO</option>
              <option selected value="FECHA">FECHA</option>
              <option value="SERVICIO">SERVICIO</option>
              <option selected value="TIPO_SERVICIO">TIPO DE SERVICIO</option>
              <option value="VEHICULO">VEHICULO</option>
            </select>
            <input type="text" style="height: 30px; width: 300px; margin-top: 2vh; border-width: 2px;"
              placeholder="Ingresa tu búsqueda" (keyup)="buscar()" #ctCadena>
            <button disabled class="botonBuscar" style="margin-top: 2vh; margin-right: 4vh;">
              <i class="fa fa-search" aria-hidden="true" *ngIf="this.buscando === false"></i>
              <div class="d-flex justify-content-center" *ngIf="this.buscando === true">
                <div class="spinner-border" style="color: #ffffff; height: 18px; width: 18px;"></div>
              </div>
            </button>
          </div>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
          <thead style="color: #ba0000;">
            <th scope="col">FOLIO</th>
            <th scope="col">EDAD</th>
            <th scope="col">GENERO</th>
            <th scope="col">FECHA</th>
            <th scope="col">SERVICIO</th>
            <th scope="col">TIPO DE SERVICIO</th>
            <th scope="col">VEHICULO</th>
            <th scope="col">BASE</th>
            <th scope="col">LUGAR</th>
            <th scope="col">Estatus</th>
            <th scope="col">Eliminar Servicio</th>
          </thead>
          <tbody>
            <tr
              *ngFor="let dato of activos | paginate: { itemsPerPage: 15, currentPage: pages, id:'activos' }; let i = index">
              <td>{{dato.FOLIO}}</td>
              <td>{{dato.EDAD}}</td>
              <td>{{dato.SEXO}}</td>
              <td>{{dato.FECHA}}</td>

              <td>{{dato.SERVICIO}}</td>
              <td>{{dato.TIPO_SERVICIO}}</td>
              <td>{{dato.VEHICULO}}</td>
              <td>{{dato.BASE}}</td>
              <td>{{dato.LUGAR}}</td>
              <td>
                <button  *ngIf="dato.ESTATUS==1" class="accion"  style="background-color: #ba0000; color: #F3F3F4; border-color: #ba0000;" (click)="id(dato.ID)"><i class="fa fa-search" ></i> En Revisión</button>
                <button  *ngIf="dato.ESTATUS==2" class="accion"  style="background-color: #ecb132; color: #F3F3F4; border-color: #ecb132;" (click)="id(dato.ID)"><i class="fa fa-search"></i> En progreso</button>
                <button  *ngIf="dato.ESTATUS==3" class="accion"  style="background-color: #00ba1c; color: #F3F3F4; border-color: #00ba1c;"(click)="id(dato.ID)"><i class="fa fa-search"></i> Completado</button>


              </td>

  <td><button id="botonazo" style="border-radius: 1vh; background-color: #ffffff; color: #F3F3F4; border: solid;border-color: #ffffff;box-shadow:-5px 6px 20px 0px rgba(187, 187, 187, 0.753) ;margin-top: -1vh;"
    (click)="eliminar(dato.ID)"> <img width="32" height="32" src="https://img.icons8.com/windows/32/waste.png" alt="waste"/> </button> </td>





            </tr>
            <tr *ngIf="this.noEncontrado === true">
              <td style="justify-content: center; text-align: center;" colspan="6"><strong>No se encontro ningun
                  usuario con ese dato</strong></td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargando === true">
            <td colspan="6">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #ba0000;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>

    </div>

  </section>
</div>


<!--Imprimir Reportes-->
<div hidden>
  <table class="table table-bordered" id="reporte">
    <tbody class="thead-light">
      <tr>
        <th scope="col">FOLIO</th>
        <th scope="col">EDAD</th>
        <th scope="col">GENERO</th>
        <th scope="col">FECHA</th>
        <th scope="col">SERVICIO</th>
        <th scope="col">TIPO DE SERVICIO</th>
        <th scope="col">VEHICULO</th>
        <th scope="col">BASE</th>
        <th scope="col">LUGAR</th>
        <th scope="col">Estatus</th>
      </tr>
      <tr *ngFor="let dato of activos; let i = index">
        <td>{{dato.FOLIO}}</td>
        <td>{{dato.EDAD}}</td>
        <td>{{dato.SEXO}}</td>
        <td>{{dato.FECHA}}</td>

        <td>{{dato.SERVICIO}}</td>
        <td>{{dato.TIPO_SERVICIO}}</td>
        <td>{{dato.VEHICULO}}</td>
        <td>{{dato.BASE}}</td>
        <td>{{dato.LUGAR}}</td>
        <td>
          <button  *ngIf="dato.ESTATUS==1" class="accion"  style="background-color: #ba0000; color: #F3F3F4; border-color: #ba0000;"><i class="fa fa-search"></i> En Revisión</button>
          <button  *ngIf="dato.ESTATUS==2" class="accion"  style="background-color: #ecb132; color: #F3F3F4; border-color: #ecb132;"><i class="fa fa-search"></i> En progreso</button>
          <button  *ngIf="dato.ESTATUS==3" class="accion"  style="background-color: #00ba1c; color: #F3F3F4; border-color: #00ba1c;"><i class="fa fa-search"></i> Completado</button>


        </td>
      </tr>
    </tbody>
  </table>



</div>


<!--
<div class="container">
  <div class="row" style="margin-top: 3vh;">
    <div id="principal" class="col-lg"
      style="justify-content: center; text-align: center; height: 550px; margin-bottom: 3vh; display: flex;">
      <canvas id="MyChart">{{ chart }}</canvas>
      <div id="cargando" *ngIf="this.spinner === true">
        <div style="align-items: center; justify-content: center; display: flex; height: 100%;">
          <div class="spinner-border" style="color: #000000; height: 60px; width: 60px;"></div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!--
<div class="col-lg" style="justify-content: left; text-align: center; display: flex;">
  <button class="boton" (click)="descargarGrafica()"><i class="fa fa-download"></i> Descargar Gráfica</button>

</div> -->
