import { Component } from '@angular/core';
import { ExcelService } from 'src/services/excel.service';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-generar-reporte',
  templateUrl: './generar-reporte.component.html',
  styleUrls: ['./generar-reporte.component.scss']
})
export class GenerarReporteComponent {
  headers = ['NOMBRE','RAZON_SOCIAL',' CELULAR','CORREO','DOMICILIO','LOCALIDAD','N_PARTICIPANTES','TIPO'];

pc:any
  constructor(
    public api: GeneralService,private excelService: ExcelService


  ) {
  }

  ngOnInit(): void {
    this.api.listaPC().subscribe((res: any) => {
      this.pc = res.body;
    });
  }


  exportar() {

    let arrayInfante: any = [];

    //Dar formato a arreglo de multas

    for (let index = 0; index < this.pc.length; index++) {

      let niño:any = [];

      niño[1] = this.pc[index].NOMBRE;
      niño[2] = this.pc[index].RAZON_SOCIAL;
      niño[3] = this.pc[index].CELULAR;
      niño[4] = this.pc[index].CORREO;
      niño[5] = this.pc[index].DOMICILIO;
      niño[6] = this.pc[index].LOCALIDAD;
      niño[7] = this.pc[index].N_PARTICIPANTES;
      niño[8] = this.pc[index].TIPO;


      arrayInfante.push(niño);
    }

    //Expotar archivo
    this.excelService.generateExcel('Reporte de estadisticas del Simulacro ',this.headers,arrayInfante);


  }

}
