import { Component } from '@angular/core';

@Component({
  selector: 'app-prevencion',
  templateUrl: './prevencion.component.html',
  styleUrls: ['./prevencion.component.scss']
})
export class PrevencionComponent {

}
