<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">

    <section>
      <div class="container">



        <div class="row justify-content-center" style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753);">
          <div class="col-12 col-md-8 col-lg-8 col-xl-6">

            <div class="row">
              <div class="col text-center">



                <img src="https://i.imgur.com/oGuTsJG.png" height="100" width="100" alt="" style="margin-top: 1vh;">


                <h1>Registro de Servicio</h1>
                <p style="margin-top: 1vh;" class="text-h3">Rellene los datos para completar su registro. </p>




              </div>
            </div>





            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Folio:
                     </label>
                <input  placeholder="{{this.data[0].FOLIO}}" class="form-control"  >
              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Cabinero:
                     </label>
                <input  placeholder="{{this.data[0].CABINERO}}" class="form-control"  >
              </div>
            </div>



            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Reportante:
                     </label>
                <input  placeholder="{{this.data[0].REPORTANTE}}" class="form-control" >
              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de Recepción de Reporte:
                     </label>
                <input  placeholder="{{this.data[0].HORA_RECEPCION}}" class="form-control"  >
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de Salida:
                     </label>
                <input  placeholder="{{this.data[0].HORA_SALIDA}}" class="form-control" >
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de Llegada al servicio:
                     </label>
                <input  placeholder="{{this.data[0].HORA_LLEGADA}}" class="form-control"  >
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                  Hora de Arribo:
                     </label>
                <input  placeholder="{{this.data[0].HORA_ARRIBO}}" class="form-control" >
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Domicilio:
                     </label>
                <input  placeholder="{{this.data[0].DOMICILIO}}"  class="form-control" >
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Colonia:
                     </label>
                <input   placeholder="{{this.data[0].COLONIA}}" class="form-control" >
              </div>
            </div>

<div class="row">

  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Género:
    </label>
    <input style="border-color: brown;" class="form-control" placeholder="{{this.data[0].SEXO}}">
  </div>

  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Edad:
    </label>
    <input style="border-color: brown;" class="form-control" placeholder="{{this.data[0].EDAD}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Género:
    </label>
    <input style="border-color: rgb(6, 97, 150);" class="form-control" placeholder="{{this.data[0].SEXO1}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Edad:
    </label>
    <input style="border-color: rgb(6, 97, 150);" class="form-control" placeholder="{{this.data[0].EDAD1}}">
  </div>

  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Género:
    </label>
    <input style="border-color: rgb(125, 49, 206);" class="form-control" placeholder="{{this.data[0].SEXO2}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Edad:
    </label>
    <input style="border-color: rgb(125, 49, 206);" class="form-control" placeholder="{{this.data[0].EDAD2}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Género:
    </label>
    <input style="border-color: rgb(231, 8, 127);" class="form-control" placeholder="{{this.data[0].SEXO3}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Edad:
    </label>
    <input style="border-color: rgb(231, 8, 127);" class="form-control" placeholder="{{this.data[0].EDAD3}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Género:
    </label>
    <input style="border-color: rgb(5, 173, 58);" class="form-control" placeholder="{{this.data[0].SEXO4}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Edad:
    </label>
    <input style="border-color: rgb(5, 173, 58);" class="form-control" placeholder="{{this.data[0].EDAD4}}">
  </div>



  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Género:
    </label>
    <input style="border-color: rgb(0, 0, 0);" class="form-control" placeholder="{{this.data[0].SEXO5}}">
  </div>


  <div class="col-3">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
      Edad:
    </label>
    <input style="border-color: rgb(0, 0, 0);" class="form-control" placeholder="{{this.data[0].EDAD5}}">
  </div>


</div>



            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Fecha:
                     </label>
                <input class="form-control"  placeholder="{{this.data[0].FECHA}}"  >
              </div>
            </div>



            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Tipo de servicio:
                     </label>
                <input placeholder="{{this.data[0].TIPO_SERVICIO}}"   class="form-control">
              </div>
            </div>

<br><br>
<div class="row">


    <div class="col-3">
      <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
       Vehículo:
           </label>
      <input placeholder="{{this.data[0].VEHICULO}}"   class="form-control">
    </div>



    <div class="col-3">
      <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
       Vehículo:
           </label>
      <input placeholder="{{this.data[0].VEHICULO1}}"   class="form-control">
    </div>



    <div class="col-3">
      <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
       Vehículo:
           </label>
      <input placeholder="{{this.data[0].VEHICULO2}}"   class="form-control">
    </div>



    <div class="col-3">
      <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
       Vehículo:
           </label>
      <input placeholder="{{this.data[0].VEHICULO3}}"   class="form-control">
    </div>



    <div class="col-3">
      <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
       Vehículo:
           </label>
      <input placeholder="{{this.data[0].VEHICULO4}}"   class="form-control">
    </div>


</div>


            <div class="row align-items-center mt-4" >
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Base:
                     </label>
                <input placeholder="{{this.data[0].BASE}}"   class="form-control">
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Conductor:
                     </label>
                <input placeholder="{{this.data[0].CONDUCTOR}}"   class="form-control">
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Personal de Apoyo:
                     </label>
                <input placeholder="{{this.data[0].PERSONAL}}"   class="form-control" >
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Servicio Especifico:
                     </label><br>
                     <input placeholder="{{this.data[0].SERVICIO}}"  class="form-control"  >
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Lugar:
                     </label><br>
                     <input placeholder="{{this.data[0].LUGAR}}"  class="form-control"  >
              </div>
            </div>


<div class="row">

<div class="col-12"> <img [src]="this.data[0].IMAGEN" width="100%" style="margin-top: 2vh;border-radius: 1vh;" alt=""></div>
<div class="col-12"> <img [src]="this.data[0].IMAGEN2" width="100%" alt="" style="margin-top: 2vh;border-radius: 1vh;"></div>
<div class="col-12"> <img [src]="this.data[0].IMAGEN3" width="100%" alt=""  style="margin-top: 2vh;border-radius: 1vh;"></div>


</div>







            <div class="row align-items-center mt-4">
              <div class="col">
                <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;">
                 Descripción del Reporte:
                     </label><br>
                <textarea style="width: 100%;border-radius: 1vh;" name="" id="" cols="30" rows="10" placeholder="{{this.data[0].DESCRIPCION}}" ></textarea>
              </div>
            </div>



<div class="row">
<div class="col-12" style="text-align: center;"><h5>Cambiar Estatus</h5></div>
<div class="col-6"><button style="background-color: rgb(232, 214, 49);border-color: rgb(232, 214, 49);color: aliceblue; border-radius: 1vh;width: 25vh;margin-top: 2vh;" (click)="progreso()"> En Progreso</button></div>

<div class="col-6"><button style="background-color: rgb(4, 125, 55);border-color: rgb(4, 125, 55);color: aliceblue; border-radius: 1vh;width: 25vh;margin-top: 2vh;" (click)="terminada()">Completado</button> </div>

</div>






            <div>


            </div>





          </div>
        </div>
      </div>
    </section>



  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
