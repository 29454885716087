<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>



<section style="background-color: rgb(255, 255, 255);margin-top: 15vh;" id="services" class="services section-bg">

  <div class="container">
    <form [formGroup]="nuevoPC2" (ngSubmit)="postForm2(this.nuevoPC2.value)">
      <div class="row">




        <h4 style="border: solid; border-color: black;margin-top: 2vh;">VI DATOS EN CASO DE TRAUMA Y AGENTE CAUSAL </h4>

        <div style="margin-top: 2.5VH;" class="col-6">

          <select class="form-select" aria-label="Default select example" formContronlName="LUGAR_VI">
            <option style="font-weight: bold;" selected>Lugar donde ocurrió:</option>
            <option value="Trabajo">Trabajo </option>
            <option value="Vía Pública">Vía Pública</option>
            <option value="Hogar">Hogar</option>
            <option value="Escuela">Escuela</option>
            <option value="Carretera">Carretera</option>
            <option value="Otro">Otro</option>

          </select>


        </div>



        <div style="margin-top: 2.5VH;" class="col-6">

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="CAIDA">
            <label class="form-check-label" for="inlineCheckbox1">Caida</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="GOLPE">
            <label class="form-check-label" for="inlineCheckbox2">Golpe</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="VOLCADURA">
            <label class="form-check-label" for="inlineCheckbox1">Volcadura</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="ARMA_BLANCA">
            <label class="form-check-label" for="inlineCheckbox2">Arma Blanca</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="COLICION">
            <label class="form-check-label" for="inlineCheckbox1">Colicion Vehicular</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="ATROPELLAMIENTO">
            <label class="form-check-label" for="inlineCheckbox2">Atropellamiento</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="ANIMAL">
            <label class="form-check-label" for="inlineCheckbox1">Animal</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="PUNZANTE">
            <label class="form-check-label" for="inlineCheckbox2">Objeto Punzante</label>
          </div>


          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="HUMANO">
            <label class="form-check-label" for="inlineCheckbox1">Ser Humano</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="FUEGO">
            <label class="form-check-label" for="inlineCheckbox2">Fuego</label>
          </div>



          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="EXPLOSION">
            <label class="form-check-label" for="inlineCheckbox1">Explosión</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="TOXICA">
            <label class="form-check-label" for="inlineCheckbox2">Sustancia tóxica</label>
          </div>



          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="ELECTRICIDAD">
            <label class="form-check-label" for="inlineCheckbox1">Electricidad</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="PROYECTIL">
            <label class="form-check-label" for="inlineCheckbox2">Proyectil de arma de fuego</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="MAQUINARIA">
            <label class="form-check-label" for="inlineCheckbox1">Herramienta/maquinaria</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="LIQUIDOS">
            <label class="form-check-label" for="inlineCheckbox2">Liquidos Calientes</label>
          </div>


        </div>







      </div>





      <div class="row">
        <div class="col-4"></div>
        <div class="col-4">

          <div style="margin-top: 2.5VH;">
            <label for="">Exploración Fisica:</label>
            <select class="form-select" aria-label="Default select example" formControlName="EXPLORACION">

              <option value="DEFORMIDAD">DEFORMIDAD</option>
              <option value="CONTUSION">CONTUSION</option>
              <option value="ABRASIONES">ABRASIONES</option>
              <option value="PENETRACIONES">PENETRACIONES</option>
              <option value="MOVIMIENTOS PARADOJICO">MOVIMIENTOS PARADOJICO</option>
              <option value="CREPITACION">CREPITACION</option>
              <option value="HERIDAS">HERIDAS</option>
              <option value="FRACTURAS">FRACTURAS</option>
              <option value="QUEMADURAS">QUEMADURAS</option>
              <option value="LACERACIONES">LACERACIONES</option>
              <option value="EDEMA">EDEMA</option>
              <option value="DOLOR">DOLOR</option>
              <option value="ALT DE LA MOVIL">ALT DE LA MOVIL</option>

            </select>


          </div>


        </div>
        <div class="col-4"></div>
      </div>

      <div class="row">
        <div class="col-4"></div>
        <div class="col-4">

          <div style="margin-top: 2.5VH;">
            Zona de Lesión
            <select class="form-select" aria-label="Default select example" formControlName="LESION">

              <option value="Mano Izquierda">Mano Izquierda</option>
              <option value="Antebrazo Izquierdo">Antebrazo Izquierdo</option>
              <option value="Brazo Izquierdo">Brazo Izquierdo</option>
              <option value="Codo Iquierdo">Codo Iquierdo</option>
              <option value="Hombro Izquierdo">Hombro Izquierdo</option>
              <option value="Pecho">Pecho</option>
              <option value="Abdomen">Abdomen</option>
              <option value="Pelvis">Pelvis</option>
              <option value="Genitales">Genitales</option>
              <option value="Gluteos">Gluteos</option>
              <option value="Cadera">Cadera</option>
              <option value="Espalda Baja">Espalda Baja</option>
              <option value="Espalda">Espalda</option>
              <option value="Cuello">Cuello</option>
              <option value="Cabeza">Cabeza</option>
              <option value="Hombro Derecha">Hombro Derecha</option>
              <option value="Brazo Derecho">Brazo Derecho</option>
              <option value="Codo Derecho">Codo Derecho</option>
              <option value="Antebrazo Derecho">Antebrazo Derecho</option>
              <option value="Mano Derecha">Mano Derecha</option>
              <option value="Pierna Derecha">Pierna Derecha</option>
              <option value="Rodilla Derecha">Rodilla Derecha</option>
              <option value="Espinilla Derecha">Espinilla Derecha</option>
              <option value="Pantorilla Derecha">Pantorilla Derecha</option>
              <option value="Tobillo Derecho">Tobillo Derecho</option>
              <option value="Pie Derecho">Pie Derecho</option>
              <option value="Pie Izquierdo">Pie Izquierdo</option>
              <option value="Tobillo Izquierdo">Tobillo Izquierdo</option>
              <option value="Pantorilla Izquierda">Pantorilla Izquierda</option>
              <option value="Espinilla Izquierda">Espinilla Izquierda</option>
              <option value="Rodilla Izquierda">Rodilla Izquierda</option>
              <option value="Pierna Izquierda">Pierna Izquierda</option>



            </select>


          </div>


        </div>
        <div class="col-4"></div>
      </div>



      <div class="row">

        <div class="col-2"></div>


        <div style="margin-top: 2.5VH;" class="col-8">
          <h4 style="border: solid; border-color: black;margin-top: 2vh;">VII ATENCION GINECO-OBSTETRICA</h4>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="GESTA">
            <label class="form-check-label" for="inlineCheckbox1">Gesta</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="CESAREAS">
            <label class="form-check-label" for="inlineCheckbox2">Cesareas</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="PARTOS">
            <label class="form-check-label" for="inlineCheckbox1">Partos</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="ABORTOS">
            <label class="form-check-label" for="inlineCheckbox2">Abortos</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="FECHA_PARTO">
            <label class="form-check-label" for="inlineCheckbox1">Fecha probable de Parto</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="SEMANAS">
            <label class="form-check-label" for="inlineCheckbox2">Semanas</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="MEMBRANA_ROTA">
            <label class="form-check-label" for="inlineCheckbox1">Membrana Rota</label>
          </div>



          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="INTEGRA">
            <label class="form-check-label" for="inlineCheckbox1">Membrana Integra</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="SANGRADO">
            <label class="form-check-label" for="inlineCheckbox2">Sangrado</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="CONTRACCIONES">
            <label class="form-check-label" for="inlineCheckbox1">Contracciones</label>
          </div>


          <div class="row">

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Frecuencia en Minutos:
              </label>
              <input class="form-control" placeholder="" formControlName="FRECUENCIA">
            </div>

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Duración en Minutos:
              </label>
              <input class="form-control" placeholder="" formControlName="DURACION">
            </div>

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Hora de nacimiento del Producto:
              </label>
              <input class="form-control" placeholder="" formControlName="HORA_NACIMIENTO">
            </div>
          </div>


          <div class="row">

            <div class="form-check form-check-inline" style="margin-top: 2vh;">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                formControlName="PRODUCTO">
              <label class="form-check-label" for="inlineCheckbox1">Producto Vivo</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                formControlName="RCP_VII">
              <label class="form-check-label" for="inlineCheckbox1">RCP</label>
            </div>


            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                formControlName="PLACENTA_EXPULSADA">
              <label class="form-check-label" for="inlineCheckbox1">Placenta Expulsada</label>
            </div>


            <br>

            <div class="col-4" style="margin-top: 2vh;">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Sexo:
              </label>
              <input class="form-control" placeholder="" formControlName="SEXO">
            </div>

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2vh;">
                Lugar:
              </label>
              <input class="form-control" placeholder="" formControlName="LUGAR_VII">
            </div>
            <div class="col-4">



              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                A.P.G.A.R:
              </label>
              <input class="form-control" placeholder="" formControlName="A_P_G_A_R">


            </div>

          </div>

          <div class="row">
            <div class="col-4">


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2vh;">
                Silverman:
              </label>
              <input class="form-control" placeholder="" formControlName="SILVERMAN">

            </div>


            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Oxigenoterapia:
              </label>
              <input class="form-control" placeholder="" formControlName="OXIGENOTERAPIA">

            </div>

            <div class="col-12">
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                OTROS:
              </label>
              <input class="form-control" placeholder="" formControlName="OTROS_VII">

            </div>




          </div>



          <div class="row">
            <h4 style="border: solid; border-color: black;margin-top: 2vh;">   VIII DATOS EN CASO DE COLISION VEHICULAR Y DEPENDENCIAS DE APOYO
            </h4>
            <div class="col-12">


              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                  formControlName="DSP">
                <label class="form-check-label" for="inlineCheckbox1">D.S.P</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                  formControlName="CRUZ_ROJA">
                <label class="form-check-label" for="inlineCheckbox1">CRUZ ROJA</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                  formControlName="PF">
                <label class="form-check-label" for="inlineCheckbox1">P.F</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                  formControlName="SEC_MOVILIDAD">
                <label class="form-check-label" for="inlineCheckbox1">SEC MOVILIDAD</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                  formControlName="PGR">
                <label class="form-check-label" for="inlineCheckbox1">P.G.R</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                  formControlName="MP">
                <label class="form-check-label" for="inlineCheckbox1">M.P.</label>
              </div>
              <br>
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Otros:
              </label>
              <input class="form-control" placeholder="" formControlName="OTRO_VIII">



            </div>



          </div>




          <div class="row">
            <div class="col-12">

              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Unidades:
              </label>
              <input class="form-control" placeholder="" formControlName="UNIDADES1">

              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Mando y Personal a cargo:
              </label>
              <input class="form-control" placeholder="" formControlName="PERSONAL">


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Unidades:
              </label>
              <input class="form-control" placeholder="" formControlName="UNIDADES2">

              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Mando y Personal a cargo:
              </label>
              <input class="form-control" placeholder="" formControlName="PERSONAL2">


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Unidades:
              </label>
              <input class="form-control" placeholder="" formControlName="UNIDADES3">


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Mando y Personal a cargo:
              </label>
              <input class="form-control" placeholder="" formControlName="PERSONAL3">



            </div>











          </div>


          <div class="row">

            <div class="col-12">
              <label for="">Vehículos Involucrados </label>

              <div class="row">


                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Tipo:
                  </label>
                  <input class="form-control" placeholder="" formControlName="TIPO">
                  <input class="form-control" placeholder="" formControlName="TIPO1">
                  <input class="form-control" placeholder="" formControlName="TIPO2">


                </div>
                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Color:
                  </label>
                  <input class="form-control" placeholder="" formControlName="COLOR1">
                  <input class="form-control" placeholder="" formControlName="COLOR2">
                  <input class="form-control" placeholder="" formControlName="COLOR3">

                </div>



                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Marca:
                  </label>
                  <input class="form-control" placeholder="" formControlName="MARCA1">
                  <input class="form-control" placeholder="" formControlName="MARCA2">
                  <input class="form-control" placeholder="" formControlName="MARCA3">
                </div>
                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Placas:
                  </label>
                  <input class="form-control" placeholder="" formControlName="PLACAS1">
                  <input class="form-control" placeholder="" formControlName="PLACAS2">
                  <input class="form-control" placeholder="" formControlName="PLACAS3">
                </div>
                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;margin-top: 2VH;">
                    Lesionados:
                  </label>
                  <input class="form-control" placeholder="" formControlName="LESIONADOS1">
                  <input class="form-control" placeholder="" formControlName="LESIONADOS2">
                  <input class="form-control" placeholder="" formControlName="LESIONADOS3">
                </div>

                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Agente del ministerio público:
                  </label>
                  <input class="form-control" placeholder="" formControlName="AGENTE">

                </div>


                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Firma:
                  </label>
                  <input class="form-control" placeholder="" formControlName="TIPO3">

                </div>


                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Ced. Profesional:
                  </label>
                  <input class="form-control" placeholder="" formControlName="CED_PROFESIONAL">

                </div>

              </div>






            </div>
          </div>





        </div>
      </div>

      <div style="justify-content: center; text-align: center;">
        <button type="submit"
          style="background-color: rgb(210, 21, 21); border-color: rgb(210, 13, 13); color: rgb(255, 255, 255); width: 25vh;margin-top: 2vh;border-radius: .5vh;">
          Enviar </button>

      </div>






    </form>
  </div>
</section>
