import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import * as dayjs from 'dayjs';



@Component({
  selector: 'app-gestion-servicios',
  templateUrl: './gestion-servicios.component.html',
  styleUrls: ['./gestion-servicios.component.scss']
})
export class GestionServiciosComponent {
  @ViewChild('cbCampo') cbCampo: ElementRef;
  @ViewChild('ctCadena') ctCadena: ElementRef;

  @ViewChild('cbCampoIn') cbCampoIn: ElementRef;
  @ViewChild('ctCadenaIn') ctCadenaIn: ElementRef;

  chart: any;
  yValues = [0, 0];
  label: any;
  canvas: any;
  imagen: any;
spinner:any

  pages: number = 1;
  activos: any;
  inactivos: any;

  sector: any;
  nombre: any;
  sectores: any = [];
  zonas: any = [];

  sectorIn: any;
  nombreIn: any;
  sectoresIn: any = [];
  zonasIn: any = [];

  noEncontrado: any;
  noEncontradoIn: any;

  //CargaDeContenido
  cargando: any;
  cargandoIn: any;
  objetivo:any
  listaObjetivos:any;
  n:any;
  dominio:any;
  listaIndicadores:any
  indicador:any
  idIndicador:any
valor:any
boton:any
tabla:any;
tablaValor:any
  //Busqueda
  buscando: any;
  buscandoIn: any;

  name = 'Servicio.xlsx';



FEMENINO:any



datosForm = new FormGroup({
  SERVICIO: new FormControl(''),
  TIPO_SERVICIO: new FormControl(''),
  VEHICULO: new FormControl('')
})

  constructor(private router: Router, private general: GeneralService, private alertas: SweetAlertService) { }

  ngOnInit() {




    this.general.listaServiciosPC().subscribe(res => {
      this.activos = res.body

      console.log(this.activos);
    })

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }


  }



  cambio() {
    this.pages = 1;
  }

  nuevo() {
    this.router.navigate(['admin/dispositivos/nuevo'])
  }

  ver(id: any) {
    this.router.navigate(['admin/dispositivos/ver/' + id])
  }

  buscar(): void {
    let columName: string = this.cbCampo.nativeElement.value;
    let value: any = this.ctCadena.nativeElement.value;

    if (value.length) {
      this.buscando = true;

      this.general.buscarServiciosPC(columName, value).subscribe((res: any) => {
        this.activos = res.body

      });


    }else{
      this.general.listaServiciosPC().subscribe(res => {
        this.activos = res.body

        console.log(this.activos);
      })
    }


  }

  id(id:any) {
    this.router.navigate(['admin/proteccion-civil/servicios/', id])
  }


  eliminar(id:any){


    this.alertas.alertaConfirmacion('Eliminar', '¿Desea confirmar la eliminación?').then((res: any) => {

      if (res.isConfirmed) {
        this.alertas.alertaRealizado().then((res: any) => {
          this.general.eliminarServicioPC(id).subscribe(res => {
            this.general.listaServiciosPC().subscribe(res => {
              this.activos = res.body
              console.log(this.activos);
              this.cargando = false;
            })
          })
        })

      }




    })



  }



  descargarDatos() {
    this.exportToExcel();
  }

  generarTablaDatos() {

    this.general.buscarServiciosPC('DOMINIO', this.tablaValor).subscribe(res => {
      this.tabla = res.body;
      console.log(this.tabla);
    })
  }



  exportToExcel(): void {
    let element = document.getElementById('reporte');
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, this.name);
  }

  reporte() {
    this.exportToExcel();
  }
}
