import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup } from '@angular/forms';
import jsPDF from 'jspdf';
import { SweetAlertService } from 'src/services/sweet-alert.service';
@Component({
  selector: 'app-registro-lista',
  templateUrl: './registro-lista.component.html',
  styleUrls: ['./registro-lista.component.scss']
})
export class RegistroListaComponent {
  @ViewChild('cbCampo') cbCampo: ElementRef;
  @ViewChild('ctCadena') ctCadena: ElementRef;

  @ViewChild('cbCampoIn') cbCampoIn: ElementRef;
  @ViewChild('ctCadenaIn') ctCadenaIn: ElementRef;

  chart: any;
  yValues = [0, 0];
  label: any;
  canvas: any;
  imagen: any;
  spinner: any
visita:any
pend:any
apro:any


  pages: number = 1;
  activos: any;
  inactivos: any;

  sector: any;
  nombre: any;
  sectores: any = [];
  zonas: any = [];

  sectorIn: any;
  nombreIn: any;
  sectoresIn: any = [];
  zonasIn: any = [];

  noEncontrado: any;
  noEncontradoIn: any;
impresion:any



  nombrer:any
 propietario:any
 celular:any
 giro:any
 domicilio:any
 colonia:any
 calles:any
 fija:any
 flotante:any
 horario:any
 numero:any
 folio:any
 recibo:any
 monto:any
 seguridad:any
 riesgo:any
 viabilidad:any
 fecha:any
 solicita:any
 extra:any


  //CargaDeContenido
  cargando: any;
  cargando1: any;
  cargandoIn: any;
  objetivo: any
  listaObjetivos: any;
  n: any;
  dominio: any;
  listaIndicadores: any
  indicador: any
  idIndicador: any
  valor: any
  boton: any
  tabla: any;
  tablaValor: any
  //Busqueda
  buscando: any;
  buscandoIn: any;

  porId: any;

  name = 'Registros.xlsx';

  FEMENINO: any

  constructor(private router: Router, private general: GeneralService,private alertas: SweetAlertService) { }

  ngOnInit() {

    this.buscando = false;
    this.noEncontrado = false;
    this.cargando = true;
    this.cargando1 = true;

    this.general.listaPcDictamen().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }




  }


  eliminar(id:any){


    this.alertas.alertaConfirmacion('Eliminar', '¿Desea confirmar la eliminación?').then((res: any) => {

      if (res.isConfirmed) {
        this.alertas.alertaRealizado().then((res: any) => {
          this.general.eliminarDictamen(id).subscribe(res => {
            this.general.listaPcDictamen().subscribe(res => {
              this.activos = res.body
              console.log(this.activos);
              this.cargando = false;
            })
          })
        })

      }




    })



  }

  cambio() {
    this.pages = 1;
  }

  buscar(): void {
    let columName: string = this.cbCampo.nativeElement.value;
    let value: any = this.ctCadena.nativeElement.value;

    if (value.length) {
      this.buscando = true;
      this.general.buscarDictamenPC(columName, value).subscribe((res: any) => {
        this.activos = res.body
        this.pages = 1;
        if (this.activos.length === 0) {
          this.noEncontrado = true;
          this.buscando = false;
        } else {
          this.noEncontrado = false;
          this.buscando = false;
        }
      });
    } else {
      this.general.listaPcDictamen().subscribe(res => {
        this.activos = res.body
        this.pages = 1;
        this.noEncontrado = false;
        this.buscando = false;
        console.log(this.activos);
      })
    }
  }

  id(id: any) {
    this.router.navigate(['admin/proteccion-civil/servicios/', id])
  }

  descargarDatos() {
    this.exportToExcel();
  }

  generarTablaDatos() {

    this.general.buscarServiciosPC('DOMINIO', this.tablaValor).subscribe(res => {
      this.tabla = res.body;
      console.log(this.tabla);
    })
  }

  exportToExcel(): void {
    let element = document.getElementById('reporte');
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, this.name);
  }

  reporte() {
    this.exportToExcel();
  }


  cerrarModalTabla() {
    let dialog = document.getElementById("modalTabla") as HTMLDialogElement;
    dialog.close();
  }

verdato(id:any){
this.router.navigate(['admin/proteccion-civil/dictamen/ver/'+ id])
}

pdf(id:any){



  this.general.DictamenID(id).subscribe(res => {
    this.impresion = res.body
    console.log(this.impresion);


this.nombre=this.impresion[0].NOMBRE
this.propietario=this.impresion[0].PROPIETARIO
this.celular=this.impresion[0].CELULAR
this.giro=this.impresion[0].GIRO
this.domicilio=this.impresion[0].DOMICILIO
this.colonia=this.impresion[0].COLONIA
this.calles=this.impresion[0].ENT_CALLES
this.fija=this.impresion[0].P_FIJA
this.flotante=this.impresion[0].P_FLOTANTE
this.horario=this.impresion[0].HORARIO
this.numero=this.impresion[0].NUMERO
this.folio=this.impresion[0].FOLIO
this.recibo=this.impresion[0].RECIBO
this.monto=this.impresion[0].MONTO
this.seguridad=this.impresion[0].SEGURIDAD
this.fecha=this.impresion[0].FECHA
this.solicita=this.impresion[0].SOLICITANTE
this.extra=this.impresion[0].EXTRA
console.log(this.nombre);
console.log(this.propietario);



this.crearPDF()


  })


}
crearPDF() {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "cm",
    format: [12.51, 6.99]

  });





  doc.setFontSize(9);

  doc.addFont('Courier', 'Arial', 'normal');
  doc.setFont('Courier');

  var logo = new Image();
  logo.src = 'assets/img/img2.png';
  doc.addImage(logo, 'JPEG', 0, 0, 12.51, 6.99);
doc.text(this.nombre,2.9, 3.3 );
{
doc.addFont('Courier', 'Arial', 'normal');
doc.setFont('Courier');
doc.setFontSize(9);
doc.text(this.propietario ,3.67, 3.62);
doc.text(this.celular ,1.45, 3.96);
doc.text(this.giro ,2.18, 4.3);
doc.text(this.domicilio ,3, 4.58);
doc.text(this.numero ,10.2, 4.59);
doc.text(this.colonia ,3.1, 4.89);
doc.text(this.calles ,2.52, 5.2);
doc.text(this.fija ,2,5.50);
doc.text(this.flotante ,4.35,5.50);
doc.text(this.horario ,6.3,5.48);
doc.text(this.solicita ,1.8,6.10);
doc.text(this.fecha ,8.4,6.85);
doc.text(this.folio ,10.5,1.1);
doc.text(this.recibo ,10.5,1.6);
doc.text(this.extra ,1,2.1);
}
{

  doc.setFontSize(10);
  doc.text( 'Monto:' + this.monto ,9,2.1);

}

doc.save('Recibo.pdf')



}

sinVisitar(id:any){

  this.general.estatusDictamen(0, id).subscribe(res => {
    this.visita = res.body;
    console.log(this.visita);

    this.general.listaPcDictamen().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })
  })
}

Pendiente(id:any){
  this.general.estatusDictamen(5, id).subscribe(res => {
    this.pend = res.body;
    console.log(this.pend);
    this.general.listaPcDictamen().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })
  })
}


Aprobado(id:any){
  this.general.estatusDictamen(2, id).subscribe(res => {
    this.apro = res.body;
    console.log(this.apro);
       this.general.listaPcDictamen().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })
  })
}

terminado(id:any){
  this.general.estatusDictamen(4, id).subscribe(res => {
    this.apro = res.body;
    console.log(this.apro);
       this.general.listaPcDictamen().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })
  })
}

temporal(id:any){
  this.general.estatusDictamen(1, id).subscribe(res => {
    this.apro = res.body;
    console.log(this.apro);
       this.general.listaPcDictamen().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })
  })
}


}
