<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">

    <section>
      <div class="container">



        <div class="row justify-content-center" style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753);">
          <div class="col-12 col-md-8 col-lg-8 col-xl-6">
            <form [formGroup]="nuevoPC" (ngSubmit)="postForm(this.nuevoPC.value)" action="">
            <div class="row">
              <div class="col text-center">



                <img src="https://i.imgur.com/oGuTsJG.png" height="100" width="100" alt="" style="margin-top: 1vh;">


                <h1>Registro</h1>
                <p style="margin-top: 1vh;" class="text-h3">Rellene los datos para completar su registro. </p>


                <label style=" justify-content: center;text-align: justify;font-weight: bold;" for=""><label style="color: rgb(214, 13, 13);font-weight: bold;" for="">
                   Hipotesis:</label> Ciclón tropical <br> Máxima Intensidad Huracan Irma categoria 4 <br>
                  <label for="" style="color: #C20406;">Efectos:</label>  <br>
                 <label for="" style="color: #034a85;">Viento:</label>  Vientos sostenidos de 220 km/h con rachas de 240 km/h,
                 especialmente en localidades cercanas a la costa. <br>
                <label for="" style="color: #034a85;">Precipitación:</label>  Acumulados de 350 mmm en un periodo de 48hrs. <br>
              <label for="" style="color: #034a85;"> Oleaje Elevado:</label>  De 4 a 6 metros de altura <br>
             <label for="" style="color: #034a85;">Marea de tormenta:</label>  De 2 a 3 metros de altura. <br>
           <label for="" style="color: #034a85;">  Daños:</label> Severos en viviendas, infraestructuras, hoteles y lineas vitales. </label>

              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col ">
                <input class="form-control" placeholder="Nombre" formControlName="NOMBRE">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col ">
<label for="">Tipo de registro:</label>
                <select class="form-select" aria-label="Default select example" formControlName="TIPO">

                  <option value="Institución">Institución</option>
                  <option value="Hogares">Hogares</option>
                  <option value="Negocios">Negocios</option>
                </select>

<br>
                <input class="form-control" placeholder="Nombre" formControlName="TIPO">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <input  class="form-control" placeholder="Razón Social" formControlName="RAZON_SOCIAL">

      <!--Leyenda-->
      <div id="animacion" class="col-12 animate__animated animate__flash" [ngClass]="{'rojo': flag, 'azul': !flag}">
        <h6 style="color: red;margin-top: 1vh;font-size: 2vh;text-align: center;">*Guarde este dato para poder generar su constancia, éste será mostrado como nombre.*</h6>
      </div>
      <!--Termina Leyenda-->




              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
                <input  class="form-control" placeholder="Correo" formControlName="CORREO">
              </div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col">
                <input  class="form-control" placeholder="Celular"  type="number" maxlength="15" formControlName="CELULAR">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <input  class="form-control" placeholder="Domicilio" formControlName="DOMICILIO">
              </div>
            </div>


            <div class="row align-items-center mt-4">
              <div class="col">
                <input  class="form-control" placeholder="Localidad" formControlName="LOCALIDAD">
              </div>
            </div>

            <div class="row align-items-center mt-4">
              <div class="col">
                <input  class="form-control" placeholder="Número de Empleados"  type="number" maxlength="10" formControlName="N_PARTICIPANTES">
              </div>
            </div>


            <div class="row justify-content-start mt-4">
              <div class="col">

                <button type="submit"  class="btn  mt-4" style="width: 100%;margin-bottom: 5vh;background-color: #C20406;color: rgb(255, 255, 255);">Enviar</button>
              </div>
            </div>



            <div>


            </div>




          </form>
          </div>
        </div>
      </div>
    </section>



  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
