import { Component } from '@angular/core';

@Component({
  selector: 'app-directores',
  templateUrl: './directores.component.html',
  styleUrls: ['./directores.component.scss']
})
export class DirectoresComponent {

}
