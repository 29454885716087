<p>solicitudes works!</p>
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>





<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">

    <section>
      <div class="container animate__animated animate__fadeInDown">



        <div class="row justify-content-center"
          style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753);">
          <div class="col-12 col-md-8 col-lg-8 col-xl-6">
            <form [formGroup]="nuevoPC" action="">
              <div class="row">
                <div class="col text-center">
                  <img src="https://i.imgur.com/oGuTsJG.png" height="94" width="94" alt="" style="margin-top: 1vh;">
                  <p style="font-weight: bolder; margin-left: 1vh;margin-top: 2vh;">DICTAMEN </p>
                  <p style="margin-top: 1vh;" class="text-h3">Datos de Dictamen del solicitante: {{this.solicita}} </p>
                </div>
              </div>

              <div class="row">

                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                    <input readonly class="form-control" id="floatingInput"  placeholder="" formControlName="NOMBRE">
                    <label for="floatingInput">Nombre o Razón Social</label>
                  </div>
                </div>


                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly  class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                      formControlName="PROPIETARIO">
                    <label for="floatingInput">Propietario o Representante Legal</label>
                  </div>

                </div>

              </div>


              <div class="row">


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" placeholder="name@example.com"
                      formControlName="CELULAR">
                    <label for="floatingInput">Celular</label>
                  </div>
                </div>


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                      formControlName="GIRO">
                    <label for="floatingInput">Giro o Actividad</label>
                  </div>
                </div>


              </div>


              <div class="row">


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control"  placeholder="name@example.com"
                      formControlName="DOMICILIO">
                    <label for="floatingInput">Domicilio Calle/Avenida</label>
                  </div>
                </div>


                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                      formControlName="NUMERO">
                    <label for="floatingInput">Número</label>
                  </div>

                </div>


              </div>


              <div class="row">


                <div class="col-6">
                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control"  placeholder="name@example.com"
                      formControlName="COLONIA">
                    <label for="floatingInput">Col/Facc/Delega/Agencia</label>
                  </div>

                </div>


                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                      formControlName="ENT_CALLES">
                    <label for="floatingInput">Entre las calles</label>
                  </div>

                </div>


              </div>


              <div class="row">


                <div class="col-6">
                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput" placeholder="name@example.com"
                      formControlName="P_FIJA">
                    <label for="floatingInput">Población Fija</label>
                  </div>
                </div>


                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                      formControlName="P_FLOTANTE">
                    <label for="floatingInput">Población flotante</label>
                  </div>

                </div>


              </div>




              <div class="row">


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput" placeholder="name@example.com"
                      formControlName="HORARIO">
                    <label for="floatingInput">Horario</label>
                  </div>
                </div>


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input  class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                      formControlName="FOLIO">
                    <label for="floatingInput">Folio</label>
                  </div>
                </div>


              </div>


              <div class="row">


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput" placeholder="name@example.com"
                      formControlName="RECIBO">
                    <label for="floatingInput">Recibo</label>
                  </div>

                </div>


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                    <input readonly class="form-control" id="floatingInput" style="margin-left: 1vh;" placeholder="name@example.com"
                     formControlName="SOLICITANTE">
                   <label for="floatingInput">Solicitante</label>
                 </div>

              </div>


              <div class="row">



                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly class="form-control" id="floatingInput"  placeholder="name@example.com"
                      formControlName="SEGURIDAD">
                    <label for="floatingInput">Seguridad</label>
                  </div>

                </div>


                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">
                     <input readonly type="date" class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                      formControlName="FECHA">
                    <label for="floatingInput">Fecha</label>
                  </div>
                </div>


              </div>





              <div class="row">



                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">
                    <input readonly class="form-control" id="floatingInput" placeholder="name@example.com"
                     formControlName="MONTO">
                   <label for="floatingInput">Monto</label>
                 </div>
                </div>


                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">
                    <input readonly class="form-control" id="floatingInput" style="margin-left: 1vh" placeholder="name@example.com"
                     formControlName="EXTRA">
                   <label for="floatingInput">Cargos Adicionales</label>
                 </div>
                </div>


              </div>

              <div class="row">



                <div class="col-6">


                  <div class="row align-items-center mt-4 form-floating mb-3">

                   <label for="floatingInput" style="font-weight: bold;">Dictamen PC - {{this.mostrarG}} </label>
                 </div>-
                </div>


                <div class="col-6">

                  <div class="row align-items-center mt-4 form-floating mb-3" style="margin-left: 1vh;">
                    <input readonly class="form-control" id="floatingInput" placeholder="name@example.com"
                     formControlName="M_MANUAL">
                   <label for="floatingInput">Monto Manual</label>
                 </div>

              </div>


           </div>
          <button style="margin-top: 5vh;" type="button" (click)=" postForm(this.id)">Editar</button>

           </div>



            </form>
          </div>
        </div>
      </div>
    </section>



  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
