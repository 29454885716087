import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-dictamen-id',
  templateUrl: './dictamen-id.component.html',
  styleUrls: ['./dictamen-id.component.scss']
})
export class DictamenIdComponent {
  razon:any
  concepto:any
  cantidad:any
  solicitante:any
   archivos: string[] = [];
 estatus:any
 activos:any
 extra:any

 id2:any
id:any
 nombre:any
propietario:any
celular:any
giro:any
domicilio:any
colonia:any
calles:any
fija:any
flotante:any
horario:any
numero:any
folio:any
recibo:any
monto:any
seguridad:any
riesgo:any
viabilidad:any
fecha:any
solicita:any
giros:any
mostrarG:any
m_monto:any
dictamen:any
estat:any
constancia_sin:any

   nuevoPC = new FormGroup({
     ID: new FormControl('', Validators.required),
     NOMBRE: new FormControl('', Validators.required),
     PROPIETARIO: new FormControl('', Validators.required),
     CELULAR: new FormControl('', Validators.required),
     GIRO: new FormControl('', Validators.required),
     DOMICILIO: new FormControl('', Validators.required),
     COLONIA: new FormControl('', Validators.required),
     ENT_CALLES: new FormControl('', Validators.required),
     P_FIJA: new FormControl('', Validators.required),
     P_FLOTANTE: new FormControl('', Validators.required),
     HORARIO: new FormControl('', Validators.required),
     NUMERO: new FormControl('', Validators.required),
     FOLIO: new FormControl('', Validators.required),
     RECIBO: new FormControl('', Validators.required),
     MONTO: new FormControl('', Validators.required),
     EXTRA: new FormControl('', Validators.required),
     SEGURIDAD: new FormControl('', Validators.required),
     FECHA: new FormControl('', Validators.required),
     SOLICITANTE: new FormControl('', Validators.required),
     M_MANUAL: new FormControl('', Validators.required),
     CONSTANCIA_DE_SINIESTRO : new FormControl('', Validators.required),
     ESTATUS : new FormControl('', Validators.required)
   });
    nuevoPC2= new FormGroup({
      ID: new FormControl('', Validators.required),
      FOLIO: new FormControl('', Validators.required),
    })

   constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router, private activerouter: ActivatedRoute,) { }

   ngOnInit(): void {
    this.id = this.activerouter.snapshot.paramMap.get('id');
    this.api.DictamenID(this.id).subscribe(res => {
      this.activos = res.body
      console.log(this.activos);

this.id2=this.activos[0].ID
this.nombre=this.activos[0].NOMBRE
this.propietario=this.activos[0].PROPIETARIO
this.celular=this.activos[0].CELULAR
this.giro=this.activos[0].GIRO
this.domicilio=this.activos[0].DOMICILIO
this.colonia=this.activos[0].COLONIA
this.calles=this.activos[0].ENT_CALLES
this.fija=this.activos[0].P_FIJA
this.flotante=this.activos[0].P_FLOTANTE
this.horario=this.activos[0].HORARIO
this.numero=this.activos[0].NUMERO
this.folio=this.activos[0].FOLIO
this.recibo=this.activos[0].RECIBO
this.monto=this.activos[0].MONTO
this.extra=this.activos[0].EXTRA
this.seguridad=this.activos[0].SEGURIDAD
this.fecha=this.activos[0].FECHA
this.solicita=this.activos[0].SOLICITANTE
this.m_monto=this.activos[0].M_MANUAL
this.constancia_sin=this.activos[0].CONSTANCIA_DE_SINIESTRO
this.estat=this.activos[0].ESTATUS

console.log(this.nombre);
console.log(this.propietario);


this.nuevoPC.setValue({
  'ID': this.id2,
  'NOMBRE': this.nombre,
  'PROPIETARIO': this.propietario,
  'CELULAR': this.celular,
  'GIRO': this.giro,
  'DOMICILIO': this.domicilio,
  'COLONIA': this.colonia,
  'ENT_CALLES': this.calles,
  'P_FIJA': this.fija,
  'P_FLOTANTE': this.flotante,
  'HORARIO': this.horario,
  'NUMERO': this.numero,
  'FOLIO': this.folio,
  'RECIBO': this.recibo,
  'MONTO': this.monto,
  'EXTRA': this.extra,
  'SEGURIDAD': this.seguridad,
  'FECHA': this.fecha,
  'SOLICITANTE': this.solicita,
  'M_MANUAL': this.m_monto,
  'CONSTANCIA_DE_SINIESTRO': this.constancia_sin,
  'ESTATUS': this.estat



 })




    })


    this.api.giroID(this.id).subscribe(res => {
      this.giros = res.body
      console.log(this.giros);
      this.mostrarG=this.giros[0].CONSTANCIA_DE_SINIESTRO
    })


  }


  postForm(id:any) {




    this.alerts.alertaConfirmacionAgregar('Edición de datos', '¿Desea enviar los cambios?')
      .then((res: any) => {

        if (res.isConfirmed) {
       {

this.nuevoPC2.value.ID=this.id2
this.nuevoPC2.value.FOLIO=this.nuevoPC.value.FOLIO
            this.api.editarDictamen(this.nuevoPC2.value.ID).subscribe(data => {



              this.alerts.realizado('Completado', 'Se han enviado los datos con exito').then((res: any) => {
location.reload()
              })

            }, error => {

              this.alerts.alertaError('Ups', 'Error de registro')
              console.log(error);

            })

          }

        }

      })

  }
 }
