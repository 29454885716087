<section style="background-color:  rgb(255, 255, 255);; height: 6vh; width: 100%;"></section>



<label style="margin-top: 15vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;"
  for="">Organización</label>
<div class="wrapper">
  <section
    style="background-color: rgb(255, 255, 255); padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 5vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let dato of organizacion " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(246, 246, 246);border-radius: 2vh;">


          <div class="col-3"><a target="_blank" href="{{dato.OBJETIVO}}">Objetivo</a></div>
          <div class="col-3"><a target="_blank" href="{{dato.ACTA_UIPC}}">Acta Constructiva de la UIPC</a></div>
          <div class="col-3"><a target="_blank" href="{{dato.ORGA_UICP}}">Organización de la UIPC</a></div>
          <div class="col-3"><a target="_blank" href="{{dato.INT_BRIGADAS}}">Integración de las brigadas</a></div>
          <div class="col-3"><a target="_blank" href="{{dato.FUNC_COORD}}">Funciones del Coordinador general de la
              UIPC</a></div>
          <div class="col-3"><a target="_blank" href="{{dato.FUNC_JEFE_PISO}}">Funciones del jefe de piso de la UIPC</a>
          </div>
          <div class="col-3"><a target="_blank" href="{{dato.FUNC_JEFE_BRIGADA}}">Funciones de jefes de la brigada de la
              UIPC</a></div>
          <div class="col-3"><a target="_blank" href="{{dato.FUNCIONES_BRIGADISTAS}}">Funciones de los brigadistas</a>
          </div>
          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{dato.FECHA}}</label> </div>
          <br>
          <br>




          <div class="row">
            <div class="col-4">


              <button  (click)="cambiarEstatusOrganizacion(0,dato.ID)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusOrganizacion(1,dato.ID)" class="revisar" *ngIf="this.token==true"  style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusOrganizacion(2,dato.ID)" class="revisar" *ngIf="this.token==true"  style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="dato.ESTATUS===2" for="">Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="dato.ESTATUS===1"  for=""  >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="dato.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>
        </div>



      </div>


    </div>

  </section>
</div>

<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;" for="">Calendario de
  Actividades</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let data of calendario " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(245, 245, 245);border-radius: 2vh;">


          <div class="col-3"><a target="_blank" href="{{data.FUNDAMENTO}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{data.CAPACITACION}}">Programa anual de la capacitacion de la
              UIPC</a></div>
          <div class="col-3"><a target="_blank" href="{{data.MANTENIMIENTO}}">Programa anual de mantenimiento</a></div>
          <div class="col-3"><a target="_blank" href="{{data.REVISION_EQUIPO}}">Programa de revisión de equipo de
              seguridad</a></div>
          <div class="col-3"><a target="_blank" href="{{data.SIMULACROS}}">Programa anual de simulacros</a></div>
          <div class="col-3"><a target="_blank" href="{{data.REVISION_PIPC}}">Programa anual de revision de PIPC</a>
          </div>
          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800;margin-bottom: 2vh; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{data.FECHA}}</label> </div>

          <br>
          <br>





          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusActividades(data.ID,0)"  class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusActividades(data.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusActividades(data.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="data.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="data.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="data.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>





        </div>



      </div>

    </div>

  </section>
</div>




<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;" for="">Directorio e
  Inventarios</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let datos of directorios " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(246, 246, 246);border-radius: 2vh;">


          <div class="col-3"><a target="_blank" href="{{datos.JUSTIFICACION}}">Justificación del directorio de
              emergencias</a></div>
          <div class="col-3"><a target="_blank" href="{{datos.DIRECTORIO_UIPC}}">Directorio de los integrantes de la
              UIPC:</a></div>
          <div class="col-3"><a target="_blank" href="{{datos.DIRECTORIO_DEPE}}">Directorio de las dependencias de
              emergencia con los que cuente el municipio y la región</a></div>
          <div class="col-3"><a target="_blank" href="{{datos.TIEMPOS_RESP}}">Tiempos de respuesta de los servicios de
              emergencia y de los que puedan participar en una emergencia con los que cuente el municipio o la
              región</a></div>
          <div class="col-3"><a target="_blank" href="{{datos.JUSTIFICACION_INVE}}">Justificación del inventario de
              recursos</a></div>
          <div class="col-3"><a target="_blank" href="{{datos.INVENTARIO}}">Inventario de equipos de emergencia y su
              ubicación indicando mayor especificación posible de los mismos(materiales,equipos y senalización)</a>
          </div>
          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{datos.FECHA}}</label> </div>

          <br>
          <br>


          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusDirectorio(datos.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusDirectorio(datos.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusDirectorio(datos.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="datos.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="datos.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="datos.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>



        </div>



      </div>

    </div>

  </section>
</div>






<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;" for="">Identificación
  de riesgos y su evaluación</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let datosa of riesgos " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(246, 246, 246);border-radius: 2vh;">


          <div class="col-3"><a target="_blank" href="{{datosa.FUNDAMENTO}}">Fundamento,exposicion y analisis de cada uno
              de los seis agentes perturbadores, asi como los antecedentes históricos relacionados con el municipio y el
              nivel de intensidad de cada uno de ellos</a></div>
          <div class="col-3"><a target="_blank" href="{{datosa.RIESGO_INTERNO}}">Análisis de riesgos internos deberá
              contemplar todo el inmueble, dicho análisis documentando todos aquellos riesgos detectados durante la
              inspección fisica</a></div>
          <div class="col-3"><a target="_blank" href="{{datosa.PREVENCION}}">Medidas de prevención y mitigación para cada
              uno de los riesgos detectados</a></div>
          <div class="col-3"><a target="_blank" href="{{datosa.PLANO_INTERNO}}">Plano interno que especifique todos los
              recuersos ante una emergencia</a></div>
          <div class="col-3"><a target="_blank" href="{{datosa.RIESGO_EXTERNO}}">Análisis de riesgos internos externos.
              Examinar la edificación y documentar los riesgos que estén en un entorno de 100 metros a la redonda</a>
          </div>
          <div class="col-3"><a target="_blank" href="{{datosa.PLANO_EXTERNO}}">Plano externo que indique la ubicación
              del unmieble en relación a los riesgos externos que deberá ubicar un radio de 500 metros</a></div>
          <div class="col-3"><a target="_blank" href="{{datosa.PLANO_E_CIRCUNDANTES}}">Plano externo que indique la
              ubicación del inmueble en relacion a los recirsos circundantes que deberáubicar un radio de 500 metros</a>
          </div>
          <div class="col-3"><a target="_blank" href="{{datosa.CARTA}}">Carta de corresponsabilidad de quien elaboró la
              identificación y evaluación de reisgos, asi como el lugar especifíco donde llevó a cabo dicho trabajo</a>
          </div>


          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: #043d6f; font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{datosa.FECHA}}</label> </div>

          <br>
          <br>


          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusRiesgos(datosa.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusRiesgos(datosa.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusRiesgos(datosa.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="datosa.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="datosa.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="datosa.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>


        </div>



      </div>

    </div>

  </section>
</div>

<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;"
  for="">Señalización,Mantenimiento y Seguridad</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let sena of senalizacion " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(245, 245, 245); border-radius: 2vh;">


          <div class="col-3"><a target="_blank" href="{{sena.FUNDAMENTO}}">Fundamento y evidencia que muestra la
              aplicación de las normas oficiales mexicanas del inmueble:</a></div>
          <div class="col-3"><a target="_blank" href="{{sena.COLORES_AVISOS}}">NOM-003-SEGOB-2011 (Señales y avisos
              para protección civil; colores,formas y simbolos a utilizar)</a></div>
          <div class="col-3"><a target="_blank" href="{{sena.COLORES_SENA}}">NPM-026-STPS-2008 (Colores y señales de
              seguridad e higiene e identificación de riesgos por fluidos conducidos en tuberias)</a></div>
          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Mantenimiento preventivo y
            correctivo</label>
          <div class="col-3"><a target="_blank" href="{{sena.FUNDA_MANT}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{sena.MEDIDAS}}">Evidencia que muestra la aplicación de medidas
              de seguridad tanto preventivas como correctivas incluyendo entre ella, sistemas eléctricos,hidráulicos
              instalaciones de gas,estructural,equipos y sistemas de seguridad,epp, entre otros</a></div>
          <div class="col-3"><a target="_blank" href="{{sena.BITACORA}}">Bitácoras de mantenimiento de los equipos de
              emergencia</a></div>

          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Medidas y equipos de
            seguridad</label>

          <div class="col-3"><a target="_blank" href="{{sena.FUN_MEDIDAS}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{sena.NORMATIVIDAD}}">Evidencia que demuestre la normatividad
              acerca de las medidas de seguridad con las que cuenta el inmueble</a></div>
          <div class="col-3"><a target="_blank" href="{{sena.EQUIPAMENTO}}">Evidencia que demuestra el equipamento con
              el que cuenta el inmueble para hacer frente a una emergencia</a></div>

          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Equipo de identificación y
            protección personal</label>


          <div class="col-3"><a target="_blank" href="{{sena.FUNDA_IDENT}}">Plano externo que indique la ubicación del
              inmueble en relación a los recursos circundantes que deberá ubicar un radio de 500 metros</a></div>
          <div class="col-3"><a target="_blank" href="{{sena.MATERIAL}}">Evidencia que demuestra la existencia de
              material de identificación, así como el equipo protección personal de la UIPC</a></div>


          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{sena.FECHA}}</label> </div>

          <br>
          <br>

          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusSenalizacion(sena.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusSenalizacion(sena.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusSenalizacion(sena.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="sena.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="sena.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="sena.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>






        </div>



      </div>

    </div>

  </section>
</div>


<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;"
  for="">Capacitación,Difusión y Concientización</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let capa of capacitacion " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(243, 243, 243);border-radius: 2vh;">


          <div class="col-3"><a target="_blank" href="{{capa.FUNDAMENTO}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{capa.PLANEACION}}">Evidencia que demuestra la planeación e
              inplementación del programa anual de capacitación teórico practico para el personal de la UIPC:</a></div>
          <div class="col-3"><a target="_blank" href="{{capa.CONSTANCIAS}}">Constancias de Capacitación</a></div>
          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Difusión y
            Concientización</label>
          <div class="col-3"><a target="_blank" href="{{capa.FUN_DIFU}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{capa.DIFUSION}}">Evidencia que demuestre la planeación de la
              difusión de las medidas de protección civil tanto para la UIPC como para el personal que labora dentro del
              lugar y personal flotante</a></div>


          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Ejercicios y
            Simulacros</label>

          <div class="col-3"><a target="_blank" href="{{capa.FUN_EJERC}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{capa.SIMULACROS}}">Programa anual de simulacros</a></div>




          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{capa.FECHA}}</label> </div>

          <br>
          <br>


          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusCapa(capa.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusCapa(capa.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusCapa(capa.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="capa.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="capa.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="capa.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>



        </div>



      </div>

    </div>

  </section>
</div>


<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;" for="">Sub-programa
  de Auxilio</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let aux of auxilio " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(247, 247, 247);border-radius: 2vh;">

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Procedimientos de
            emergencia</label>
          <div class="col-3"><a target="_blank" href="{{aux.FUNDAMENTO}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{aux.ALERTAMIENTO}}">Evidencia que demuestra los mecanismos de
              la evaluación integral,así como su canalización para su absorción y/o seguimiento</a></div>

          <br>

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Vuelta a la
            normalidad</label>
          <div class="col-3"><a target="_blank" href="{{aux.FUN_DANOS}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{aux.MECANISMOS}}">Evidencia que demuestre los procedimientos
              para la vuelta a la normalidad posterior a una emergencia</a></div>

          <br>



          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Plan de Contingencia</label>
          <div class="col-3"><a target="_blank" href="{{aux.FUN_CONTIN}}">Fundamento</a></div>



          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Evaluación inicial del
            riesgo de cada puesto de trabajo</label>

          <div class="col-3"><a target="_blank" href="{{aux.FUN_NORMA}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{aux.NORMALIDAD}}">Evidencia que demuestre los procedimientos
              en la aplicación de la evaluación de riesgo de cadad puesto de trabajo del inmueble</a></div>




          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{aux.FECHA}}</label> </div>

          <br>
          <br>

          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusAuxilio(aux.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusAuxilio(aux.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusAuxilio(aux.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="aux.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="aux.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="aux.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>




        </div>



      </div>

    </div>

  </section>
</div>


<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;" for="">Sub-programa
  de Auxilio (Riegos)</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let auto of autoproteccion " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(245, 245, 245);border-radius: 2vh;">

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Valoración de riesgo</label>
          <div class="col-3"><a target="_blank" href="{{auto.FUNDAMENTO}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{auto.RIESGO}}">Evidencia que demuestra el nivel de riesgo del
              inmueble a través de una metodología de evaluación</a></div>

          <br>

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Medidas y acciones de
            autoprotección</label>
          <div class="col-3"><a target="_blank" href="{{auto.FUND_MED}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{auto.TRABAJOS}}"> Evidencia que demuestre los trabajos y
              mitigación de riesgo para el puesto de trabajo</a></div>

          <br>



          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Difusión y
            socialización</label>
          <div class="col-3"><a target="_blank" href="{{auto.FUND_DIFU}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{auto.FUND_LEGAL}}">Evidencia que demuestre la
              difusión,sensibilización y socialización sobre los riesgos a los que se estan expuestos cada
              uno de los empleados así como todas aquellas actividades de capacitación e información sobre la mitigación
              de cada uno de ellos</a></div>



          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Plan de continuidad de
            operaciones , fundamento legal</label>

          <div class="col-3"><a target="_blank" href="{{auto.EVIDENCIA}}">Evidencia documentada que muestre el respaldo
              y fundamento legal del plan de continuidad de operaciones</a></div>




          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{auto.FECHA}}</label> </div>

          <br>
          <br>

          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusAuto(auto.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusAuto(auto.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusAuto(auto.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="auto.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="auto.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="auto.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>



        </div>



      </div>

    </div>

  </section>
</div>


<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;"
  for="">Proposito,Sedes alternas y linea de sucesión</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let propo of proposito " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(242, 242, 242); border-radius: 2vh;">

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Propósito</label>
          <div class="col-3"><a target="_blank" href="{{propo.PROPOSITO}}">Evidencia que demuestre y fundamente el
              propósito de plan de continuidad de operación</a></div>

          <br>

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Funciones criticas o
            esenciales</label>
          <div class="col-3"><a target="_blank" href="{{propo.FUN_CRITICA}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{propo.FUNCIONES}}"> Evidencia que demuestra las funciones
              minimas
              indispensables, criticas o escenciales que el inmueble no puede
              interrumpir o dejarde hacer para su funcionamiento</a></div>

          <br>



          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Sedes Alternas
          </label>
          <div class="col-3"><a target="_blank" href="{{propo.FUN_SEDES}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{propo.SEDE}}">Evidencia que contemple de ser necesario con
              una sede alterna-interna al área de trabajo u externa del inmueble, tomando en cuenta la logística que
              esto implica para trasladar
              sus funciones criticas escenciales a la sede alterna tales como recursos humanos,recursos fisicos,
              logística,servicios, seguridad etc</a></div>



          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Línea de sucesión o cadena
            de mando</label>
          <div class="col-3"><a target="_blank" href="{{propo.FUN_LINEA}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{propo.CADENA}}">Evidencia que demuestre la cadena de mando
              del inmueble seccionada según las necesidades del propio inmueble</a></div>




          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{propo.FECHA}}</label> </div>

          <br>
          <br>


          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusPropo(propo.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusPropo(propo.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusPropo(propo.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="propo.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="propo.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="propo.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>



        </div>



      </div>

    </div>

  </section>
</div>



<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;" for="">Recursos
  humanos,dependencias,interferencias y requerimentos minimos</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let recu of recursos " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(242, 242, 242);border-radius: 2vh;;">

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Recursos Humanos</label>
          <div class="col-3"><a target="_blank" href="{{recu.FUNDAMENTOS}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{recu.PLANTILLA}}"> Evidencia que muestre la plantilla de
              personal del inmueble que permita dar pauta al plan de continuidad de operaciones involucrandolas
              acciones propias de la unidad interna de Protección Civil</a></div>

          <br>



          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Dependencias e
            interferencias
          </label>
          <div class="col-3"><a target="_blank" href="{{recu.FUN_DEPE}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{recu.INMUEBLE}}">Evidencia que demuestre todas aquellas
              actividades que se llevan a cabo al interior del inmueble por las distintas áreas identificando su
              interrelacion asi como la interrelación que se pudiera tener otros inmuebles que dependan de nuestro
              producto o trabajo,
              asi como del material y materia prima del que depende nuestro inmueble para llevar a cabo el trabajo</a>
          </div>



          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Requerimientos Minimos
          </label>
          <div class="col-3"><a target="_blank" href="{{recu.FUN_REQUE}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{recu.REQUERIMENTOS}}">Evidencia que demuestre los requerimentos
              minimos contemplados para mantener en operación las funciones básicas del inmueble</a></div>




          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{recu.FECHA}}</label> </div>

          <br>
          <br>

          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusRecu(recu.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusRecu(recu.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusRecu(recu.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="recu.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="recu.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="recu.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>




        </div>



      </div>

    </div>

  </section>
</div>



<label style="margin-top: 1vh;width: 100%; text-align: center;font-size: 4vh; color:#043d6f ;font-weight: bolder;"
  for="">Interoperabilidad de las Comunicaciones, Protección y Respaldo y Activación del Plan</label>
<div class="wrapper" style="justify-content: center; text-align: center;">
  <section
    style="background-color: rgb(255, 255, 255);; padding-left: 5vh; padding-right: 5vh; overflow: scroll;border-radius: 2vh;height: 30vh;margin-top: 2vh;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">





        <div *ngFor="let comu of comunicaciones " class="row"
          style="background-color: rgb(255, 255, 255); border: solid;border-color: rgb(247, 247, 247);border-radius: 2vh;;">

          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Interoperabilidad de las
            comunicaciones</label>
          <div class="col-3"><a target="_blank" href="{{comu.FUNDAMENTOS}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{comu.COMUNICACIONES}}"> Evidencia que muestre el plan para que
              el sistema de comunicaciones al interior y al exterior no cese</a></div>

          <br>



          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Protección y respaldo de la
            información y base de datos
          </label>
          <div class="col-3"><a target="_blank" href="{{comu.FUN_PROTE}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{comu.PROTECCION}}">Evidencia que demuestre el método para la
              protección y respaldo de su información y base de datos</a></div>



          <br>
          <label for="" style="font-size: 2vh; font-weight: bolder;color: rgb(7, 76, 136);">Activación del plan
          </label>
          <div class="col-3"><a target="_blank" href="{{comu.FUN_PLAN}}">Fundamento</a></div>
          <div class="col-3"><a target="_blank" href="{{comu.PLAN}}">Evidencia que demuestre el precedimiento para la
              puesta en marcha el plan de continuidad de operaciones</a></div>




          <div class="col-3"> <label for=""
              style="font-size: 2vh;color: rgb(4, 61, 111); font-weight: 800; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
              {{comu.FECHA}}</label> </div>

          <br>
          <br>


          <div class="row">
            <div class="col-4">


              <button (click)="cambiarEstatusComu(comu.ID,0)" class="revisar" *ngIf="this.token==true" style="background-color:  #da0505; color: aliceblue;border: solid;border-color: #da0505; width: 15vh;height: 4vh;border-radius: 1vh;margin-top: 5vh;" >Sin Revisar</button>
              <button (click)="cambiarEstatusComu(comu.ID,1)" class="revisar" *ngIf="this.token==true" style="background-color: #f4c405; color: aliceblue;border: solid;border-color: #f4c405;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;">En Revisión</button>
              <button (click)="cambiarEstatusComu(comu.ID,2)" class="revisar" *ngIf="this.token==true" style="background-color: #008017; color: aliceblue;border: solid;border-color: #008017;  width: 15vh;height: 4vh;border-radius: 1vh; margin-left: 2vh;margin-top: 5vh;margin-bottom: 2vh;">Aprobado</button>

            </div>

            <div class="col-4"></div>
            <div class="col-4">

              <label *ngIf="comu.ESTATUS===2" for="" >Estatus: <label for="" style="color: #008017;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Aprobado  </label>   </label>
              <label  *ngIf="comu.ESTATUS===1"  for="" >Estatus: <label for="" style="color: #f4c405;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">En Revisión  </label>   </label>
              <label  *ngIf="comu.ESTATUS===0"  for="" >Estatus: <label for="" style="color: #da0505;margin-top: 5vh;font-weight: bolder;font-size: 2vh;">Sin Revisar  </label>   </label>
            </div>

            </div>




        </div>



      </div>

    </div>

  </section>
</div>
