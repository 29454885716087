import { Component , ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-registros-empresa',
  templateUrl: './registros-empresa.component.html',
  styleUrls: ['./registros-empresa.component.scss']
})
export class RegistrosEmpresaComponent {


busqueda:any
empresa:any
organizacion:any
calendario:any
directorios:any
riesgos:any
senalizacion:any
capacitacion:any
auxilio:any
autoproteccion:any
proposito:any
recursos:any
comunicaciones:any
token:any
estatusOrga:any



constructor(private router: Router, private general: GeneralService) { }


  ngOnInit() {







  this.empresa=localStorage.getItem('empresa')
        this.general.buscarDatosOrganizacion('EMPRESA', this.empresa).subscribe((res: any) => {
          this.organizacion = res.body
         console.log(this.organizacion);


        });



        this.empresa=localStorage.getItem('empresa')
        this.general.buscarDatosCalendario('EMPRESA', this.empresa).subscribe((res: any) => {
          this.calendario = res.body
         console.log(this.calendario);


        });


        this.empresa=localStorage.getItem('empresa')
        this.general.buscarDatosDirectorios('EMPRESA', this.empresa).subscribe((res: any) => {
          this.directorios = res.body
         console.log(this.directorios);

        });



        this.empresa=localStorage.getItem('empresa')
        this.general.buscarDatosRiesgos('EMPRESA', this.empresa).subscribe((res: any) => {
          this.riesgos = res.body
         console.log(this.riesgos);


        });

        this.empresa=localStorage.getItem('empresa')
        this.general.buscarDatosSenalizacion('EMPRESA', this.empresa).subscribe((res: any) => {
          this.senalizacion = res.body
         console.log(this.senalizacion);


        });


        this.empresa=localStorage.getItem('empresa')
        this.general.buscarDatosCapacitacion('EMPRESA', this.empresa).subscribe((res: any) => {
          this.capacitacion = res.body
         console.log(this.capacitacion);
         localStorage.setItem('empresa', this.busqueda[0].EMPRESA)
  this.router.navigate(['admin/proteccion-civil/pc/interno/lista/registros'])


        });



        this.empresa=localStorage.getItem('empresa')
        this.general.buscarAuxilio('EMPRESA', this.empresa).subscribe((res: any) => {
          this.auxilio = res.body
         console.log(this.auxilio);


        });


        this.empresa=localStorage.getItem('empresa')
        this.general.buscarDatosAutoproteccion('EMPRESA', this.empresa).subscribe((res: any) => {
          this.autoproteccion = res.body
         console.log(this.autoproteccion);


        });




        this.empresa=localStorage.getItem('empresa')
        this.general.buscarProposito('EMPRESA', this.empresa).subscribe((res: any) => {
          this.proposito = res.body
         console.log(this.proposito);


        });



        this.empresa=localStorage.getItem('empresa')
        this.general.buscarRecursos('EMPRESA', this.empresa).subscribe((res: any) => {
          this.recursos = res.body
         console.log(this.recursos);


        });





        this.empresa=localStorage.getItem('empresa')
        this.general.buscarComunicaciones('EMPRESA', this.empresa).subscribe((res: any) => {
          this.comunicaciones = res.body
         console.log(this.comunicaciones);


        });


        if(localStorage.getItem('token')){
          this.token=true
        }
  }

  cambiarEstatusOrganizacion(estatus:any,id:any){
     this.general.estatusOrga(id,estatus).subscribe((res: any) => {
      this.general.buscarDatosOrganizacion('EMPRESA', this.empresa).subscribe((res: any) => {
        this.organizacion = res.body
       console.log(this.organizacion);


      });




        });
  }

  cambiarEstatusActividades(id:any,estatus:any){

    this.general.estatuscalendario(id,estatus).subscribe((res: any) => {
      this.general.buscarDatosCalendario('EMPRESA', this.empresa).subscribe((res: any) => {
        this.calendario = res.body
       console.log(this.calendario);


      });




        });


  }

  cambiarEstatusDirectorio(id:any,estatus:any){


    this.general.estatusdirectorios(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')

        this.general.buscarDatosDirectorios('EMPRESA', this.empresa).subscribe((res: any) => {
          this.directorios = res.body
         console.log(this.directorios);

        });



        });




  }

  cambiarEstatusRiesgos(id:any,estatus:any){
    this.general.estatusriesgos(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')
      this.general.buscarDatosRiesgos('EMPRESA', this.empresa).subscribe((res: any) => {
        this.riesgos = res.body
       console.log(this.riesgos);

      });



        });
  }

  cambiarEstatusSenalizacion(id:any,estatus:any){
    this.general.estatusSena(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')
      this.general.buscarDatosSenalizacion('EMPRESA', this.empresa).subscribe((res: any) => {
        this.senalizacion = res.body
       console.log(this.riesgos);

      });



        });
  }

  cambiarEstatusCapa(id:any,estatus:any){
    this.general.estatuscapactacion(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')
      this.general.buscarDatosCapacitacion('EMPRESA', this.empresa).subscribe((res: any) => {
        this.capacitacion = res.body
       console.log(this.capacitacion);

      });



        });
  }
  cambiarEstatusAuxilio(id:any,estatus:any){
    this.general.estatusauxilio(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')
      this.general.buscarAuxilio('EMPRESA', this.empresa).subscribe((res: any) => {
        this.auxilio = res.body
       console.log(this.auxilio);


      });



        });
  }
  cambiarEstatusAuto(id:any,estatus:any){
    this.general.estatusautoprpteccion(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')
      this.general.buscarDatosAutoproteccion('EMPRESA', this.empresa).subscribe((res: any) => {
        this.autoproteccion = res.body
       console.log(this.autoproteccion);


      });



        });
  }


  cambiarEstatusPropo(id:any,estatus:any){
    this.general.estatuspropo(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')

      this.general.buscarProposito('EMPRESA', this.empresa).subscribe((res: any) => {
        this.proposito = res.body
       console.log(this.proposito);


      });


        });
  }

  cambiarEstatusRecu(id:any,estatus:any){
    this.general.estatusrecursos(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')
      this.general.buscarRecursos('EMPRESA', this.empresa).subscribe((res: any) => {
        this.recursos = res.body
       console.log(this.recursos);


      });



        });
  }

  cambiarEstatusComu(id:any,estatus:any){
    this.general.estatuscomunicaciones(id,estatus).subscribe((res: any) => {
      this.empresa=localStorage.getItem('empresa')
      this.general.buscarComunicaciones('EMPRESA', this.empresa).subscribe((res: any) => {
        this.comunicaciones = res.body
       console.log(this.comunicaciones);


      });



        });
  }

}
